
import React from 'react'
import './product-page.scss'
import CardUserCabinet from '../../pages/CardUserCabinet'
import CardDoOrder from '../../pages/CardDoOrder'
import { Link } from 'react-router-dom';

// import Footer from '../footer/Footer'
import $api from '../requests/index'
import {useSelector, useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import {favoritesSelector,addToFavorites, authSelector, ordersSelector,userSelector, getProductsSelector, getLoading, getBusketSelector, addBusket} from '../slices/SlicesStore';
import BannerPage from '../header/BannerPage'


function OrderDekstop() {
    const [products_id, setProducts] = React.useState([])
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const auth = useSelector(authSelector.getAuth)
    const isLoading = useSelector(getLoading.getIsLoading)
    const busket = useSelector(getBusketSelector.getBusket);
    const products = useSelector(getProductsSelector.getProducts)
    const user = useSelector(userSelector.getUser)
    const [typePochta, setType]= React.useState(null);
    const [promoIsActive, setPromoIsActive] = React.useState(null);
    const [promoText, setPromoText]  = React.useState(null);
    const [incorr, setIncor] = React.useState('');
    const [fio, setFio] = React.useState(null);
    const [sale, salePromo] = React.useState(0);
    const [email, setEmail] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [pvzAddress, setPvz]  = React.useState(null);
    const [promo, setPromo]  = React.useState(null);
    
    const [deliverCost, setDeliveryCost] = React.useState(0)
    const [prodCount, setProdCount] = React.useState(0);
    const [countrySuggest , setCountrySuggest] = React.useState([]);
    const [postVar, changePost] = React.useState(null);
    const [mapActive, setMap] = React.useState(false);
    const[summ,setSumm] = React.useState(0);
    useEffect(() => {
        if (user){
            if (user.email){
                setEmail(user.email)
            }
            if (user.name){
                setFio(user.name)
            } 
            if (user.phone){
                setPhone(user.phone)
            }
        }

    }, [user])
    const handleName =(e) =>{
        setFio(e.target.value)
    }
    const handleEmail =(e) =>{
        setEmail(e.target.value)
    }
    const handlePhone =(e) =>{
        setPhone(e.target.value)
    }
    const handlePromoChange =(e) =>{
        setPromo(e.target.value)
    }

    const onChooseCdek=(e) =>{

        setPvz(e.address)
        setType('СДЭК')
        setDeliveryCost(Number(e.price))
    }
    const onChooseRussia =(e) =>{
        setType('Почта России')
        setPvz(e.regionTo+ ", "+ e.cityTo + ", " + e.addressTo)
        setDeliveryCost(Number(e.cashOfDelivery)/100)
    }
    const onChooseBoxberry =(e) =>{
        setPvz(e.address)
        setType('Boxberry')
        setDeliveryCost(Number(e.price))
    }



    // const handleBoxberry = (e) =>{
    //     window.boxberry.openOnPage("boxberry_map")
    //     var widget3 = window.boxberry.open(handleRussia_function)

    // }
    const orders = useSelector(ordersSelector.getOrders)
    const [data, setData] = React.useState([])
    let summary = 0;

    React.useEffect(() => {
        let temp = []
        let summary = 0
        let products_id = []
        if (!isLoading){
            console.log(busket)
            products.map((event) => {
                busket.map((elem) => {
                    if (event.id === elem.id){
                        products_id.push(`${event.id}|${elem.count}|${event.newPrice * elem.count}`)
                        temp.push(Object.assign({}, event, elem))
                        summary += event.newPrice * elem.count;
    
                    }
    
                })
            })
            console.log(temp)
            setData(temp)
            setProducts(products_id)
            console.log(summary)
            setSumm(summary)
          }
    }, [isLoading, busket])
    const addApply = () => {
        console.log(1)
        if (!(email&& phone && fio)){
            setIncor("Не все поля заполнены")
        }
        if (email&& phone && fio && data.length !== 0){
            
            console.log(2)
            let pochta;
            let nameTo;
            let phoneTo;
            if (user.email){
                pochta = user.email
            } else {
                pochta = email
            }
            if (user.name){
                nameTo = user.name
            } else {
                nameTo = fio
            }
            if (user.phone){
                phoneTo = user.phone
            } else {
                phoneTo = phone
            }
            // $api.post(`/getPaymentLink`, {cost:Math.floor((summary+deliverCost)*(1 - sale/100)), email: pochta, user: user})
            // .then(function(e) {
            //     // window.close()
            //     window.location.assign(e.data.link)
                
            //     if (!user){
            //         $api.post(`/checkPayment`, {id :e.data.id, email:  pochta, phone: phoneTo, fio: nameTo, cost:Math.floor((summary+deliverCost)*(1 - sale/100)), products_id: products_id, address: pvzAddress, user: user, type: typePochta, promo: promoIsActive, sale: sale, names: names})
            //     } else {
            //         $api.post(`/checkPayment`, {id :e.data.id, email:  pochta, phone: phoneTo, fio: nameTo, cost:Math.floor((summary+deliverCost)*(1 - sale/100)), products_id: products_id, address: pvzAddress, user: user,  type: typePochta, promo: promoIsActive, sale: sale, names: names})
            //     }
                
            //     // .then(function(elem) {
            //     //     console.log("OKAY")
            //     //     console.log(elem.data)
            //     // })

            // })
            $api.post(`/user/addApply`, {user: {email: pochta, phone: phoneTo, name: nameTo}, email: pochta, products_id: products_id, description: description, cost: summ, auth: Boolean(user)})
            .catch(function (error) {
                setIncor(error.response.data.message)
            
            })

        }

    }

    const [description, changeDescription] = React.useState('')
  return (
    <>
        {!isLoading ? 
            <div className='product__page__container'>
            <div className="info_heade">
              <h1>Оформление заказа</h1>
          </div>
        <div className="page__container">
            <section id="order__information__block">
                {data.length !== 0 ? data.map((obj,index) =>  <CardDoOrder id={obj.id} key={index} name={obj.name} imgUrl={`/${obj.imageUrl}/1.webp`} newPrice={obj.newPrice} size={obj.size} count={obj.count}/>): <p className='nothing-busket'>Еще ничего не добавлено</p>}
                <div className="change__information__block">
                    {/* <h2>Информация</h2> */}
                    {/* <div className="number-change">
                        <p>Телефон</p>
                        {!user ? <input onChange={handlePhone} placeholder='+7(999)-721-64-13' type='text'></input>: <input onChange={handlePhone} value={user.phone} type='text'></input>}
                    </div> */}
                    {/* <div className="email-change">
                        <p>E-mail</p>
                        {!user ?  <input onChange={handleEmail} placeholder='ivan2000@gmail.com' type='email'></input>:  <input onChange={handleEmail} value={user.email} type='email'></input>}
        
                    </div> */}
                    {/* <div className="fio-change">
                        <p>ФИО</p>
                        {!user ?  <input onChange={handleName} placeholder='Иванов Иван Иванович' type='text'></input>:  <input onChange={handleName} value={user.name} type='text'></input>}
                    </div> */}
                </div>
                
            </section>
            <section id="order__add__block">
            <h2>Информация</h2>
                <div className="basket-discrip">
                    <div className="tovar">
                        <p>Товар, {busket.length}шт.</p>
                        <h3>{summ} ₽</h3>
                    </div>
                    
                </div>
                
                {/* <div className="basket-discrip">
                    <div className="tovar">
                        <p>Описание заявки</p>
                        <input onChange={(e) => changeDescription(e.target.value)}placeholder='Введите описание заявки'></input>
                    </div>      
                </div> */}
                {/* поля */}
                <div className="change__information__block">
                    <div className="number-change">
                        <p>Телефон</p>
                        {!user ? <input onChange={handlePhone} placeholder='+7(999)-721-64-13' type='text'></input>: <input onChange={handlePhone} value={user.phone} type='text'></input>}
                    </div>
                    <div className="email-change">
                        <p>E-mail</p>
                        {!user ?  <input onChange={handleEmail} placeholder='ivan2000@gmail.com' type='email'></input>:  <input onChange={handleEmail} value={user.email} type='email'></input>}
        
                    </div>
                    <div className="fio-change">
                        <p>ФИО</p>
                        {!user ?  <input onChange={handleName} placeholder='Иванов Иван Иванович' type='text'></input>:  <input onChange={handleName} value={user.name} type='text'></input>}
                        {/* <input onChange={handleName} type='text'></input> */}
                    </div>
                </div>
                <p>{incorr}</p>
                <div onClick={() => addApply()} className="add__button">
                    <p >Оставить заявку</p>
                </div>
                <p className="discription">
                    Нажимая на кнопку «подтвердить заказ», я принимаю условия <Link to="/policy">политики конфиденциальности</Link>
                </p>
                <p className="discription">
                    Любая представленная информация не является публичной
                </p>
            </section>
        </div>
    </div>
        
        :
        null
        }
    
    
    
    </>


        
  )
}

export default OrderDekstop
