import React from 'react'
// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




function SliderImages({data}) {
  return (
    <Swiper
    
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={0}
      navigation={true}
      slidesPerView={1}
      loop={true}
      keyboard={true}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        1025 : {
          slidesPerView : 1,
          spaceBetween : 30
        }

    }}
    >

    { data.map((elem) => 
    <>
          <SwiperSlide>
        <div className="baner-full-slide">
            {elem}
        </div>

        </SwiperSlide>
    
    </>
  )}

      
    </Swiper>
  )
}

export default SliderImages
