import React from 'react'
// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import TestCard from './TestCard';
import Card from '../../components/content/products/Card'
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function SliderHome({data}) {
  return (
    <Swiper
    
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={30}
      slidesPerView={3.5}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        375 : {
          slidesPerView : 1.5,
          spaceBetween : 15
        },
        430 : {
          slidesPerView : 1.5,
          spaceBetween : 15
        },
        768 : {
          slidesPerView : 2.5,
          spaceBetween : 30
        },
        // media
        1025 : {
          slidesPerView : 2,
          spaceBetween : 30
        },
        1325 : {
          slidesPerView : 3.5,
          spaceBetween : 30
        },
        1525 : {
          slidesPerView : 3.5,
          spaceBetween : 30
        }
    }}
    >
        {data.slice(0,15).map((obj,index) => 
            <SwiperSlide>
                  <Card 
                    id={obj.id}
                    key={obj.id}
                    name={obj.name}
                    newPrice={obj.newPrice}
                    prevPrice={obj.prevPrice}
                    // size={obj.size}
                    // sizes = {obj.sizes}
                    image_url={obj.imageUrl}
                    imgCount={obj.imgCount}
                  />
            </SwiperSlide>
        )}
      
    </Swiper>
  )
}

export default SliderHome
