
import './App.css';

import './resets.css'
import React from 'react'
import Catalog from './components/content/Catalog';
import { setAuth, setUser, setFavorites, setProducts, setBusket, setOrders, getLoading} from './components/slices/SlicesStore';
import $api from './components/requests/index'
import {getProducts} from './components/requests/index'
import Favorite from './pages/Favorited/Favorited';
import Preloader from './components/Preloader';
import {BrowserRouter,Route,Routes} from 'react-router-dom'; 
import UserPage from './pages/UserPage';
import ErrorPage from './pages/ErrorPage';
import ScrollOnTop from './components/ScrollOnTop';
import { useDispatch, useSelector} from 'react-redux'
import HomePage from './pages/Home/HomePage';
import ProductPage from './components/orderpage/ProductPage';
import CardPage from './pages/CardPage';
import VkLogin from './pages/VkLogin';
import About from './pages/About';
import AdminLog from './pages/AdminLog';
import AdminOrder from './pages/AdminOrder';
import AdminPanel from './pages/AdminPanel';
import ResetPass from './pages/ResetPass';
import * as VKID from '@vkid/sdk';
import Store from './pages/Store'
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Policy from './pages/Policy';
function App() {
  const dispatch = useDispatch()
  VKID.Config.set({
    app: 51819507, // Идентификатор приложения.
    redirectUrl: 'https://streetcube.ru/vk_auth', // Адрес для перехода после авторизации.
  });
  const [screenLoading, setScreenLoading] = React.useState(false);
  const isLoading = useSelector(getLoading.getIsLoading)
  async function checkAuth() {
    const response = await $api.get('https://agent-kei.ru/api/user', {withCredentials: true}) //https://streetcube.ru/api/user to server
    if (response?.request?.status === 200){
      console.log(response.data)
      dispatch(setUser(response.data.userData))
      dispatch(setAuth(true))
      dispatch(setFavorites(response.data.liked))
      dispatch(setBusket(response.data.busket))
      console.log(response.data.apply, "APPLY")
      dispatch(setOrders(response.data.apply))
  } else {
    
    if (localStorage.getItem("favorite")){
        let data = JSON.parse(localStorage.getItem("favorite"))
        dispatch(setFavorites(data))
    }
    if (localStorage.getItem("busket")){

      let data = JSON.parse(localStorage.getItem("busket"))
      dispatch(setBusket(data))
  }
      
  }
 }
  checkAuth()
  getProducts().then((res) => {
    dispatch(setProducts(res))
    console.log(res)
  })
  const loader = document.getElementById("loader");

  React.useEffect(() => 
  {
    if (!isLoading){
        setTimeout(() => loader.style.display = "none", 300)
        
      }
  
    
  }, [isLoading]);


  
  
  return (
    <div>
      
      <BrowserRouter>
        <Header/>
        <ScrollOnTop />
        <Routes>
            <Route path="/vk_auth" element={ <VkLogin /> }/>
            <Route path='/catalog' element={<Catalog exFilter={'no'}/>} />
            <Route path='/catalog/sales' element={<Catalog exFilter={"sale"}/>} />
            <Route path="/" element={ <HomePage /> }/>
            <Route path='/userpage' element={<UserPage/>}/>
            <Route path='/favorited' element={<Favorite/>}/>
            <Route path='/catalog/:id' element={<CardPage/>} />
            <Route path='/prodpage' element={<ProductPage/>} />
            <Route path='/about' element={<About page='1'/>} />
            <Route path='/dostavka-i-oplata' element={<About page='2'/> } />
            <Route path='/vozvrat-i-garantiya' element={<About page='3'/>} />
            <Route path='/remont' element={<About page='4'/>} />
            <Route path='/kontakty' element={<About page='5'/>} />
            <Route path='/trade-in' element={<About page='6'/>} />
            {/* <Route path='/store' element={<Store/>} /> */}
            <Route path='*' element={<ErrorPage/>}/>
            <Route path='/reset_password' element={<ResetPass/>}/>
            <Route path='/policy' element={<Policy/>}/>
            {/* <Route path='/admin_login' element={<AdminLog/>}/>
            <Route path='/adminka_panelb' element={<AdminPanel/>}/>
            <Route path='/adminka_panelb/:id' element={<AdminOrder/>}/> */}
        </Routes>
        <Footer/>
      </BrowserRouter>
      
    </div>

  );
}

export default App;
