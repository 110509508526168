import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import './home.scss'
// images
import iphone from '../../images/apple_icon/iphone.svg'
import watch from '../../images/apple_icon/watch.svg'
import pc from '../../images/apple_icon/pc.svg'
import iPad from '../../images/apple_icon/iPad.svg'
import air_pods from '../../images/apple_icon/air_pods.svg'
import ps10 from '../../images/apple_icon/ps10.png'
import acses from '../../images/apple_icon/acses.svg'
import aiphoneIMG from '../../images/apple_icon/iphoneIMG.jpg'
import watchIMG from '../../images/apple_icon/watchIMG.png'
import macPro from '../../images/apple_icon/mac_pro.png'
import dyson from '../../images/apple_icon/dyson.png'
import dyson_icon from '../../images/apple_icon/dyson-icon.svg'
import ps_icon from '../../images/apple_icon/ps-icon.svg'
import samsung_icon from '../../images/apple_icon/samsung.svg'
import repair_icon from '../../images/apple_icon/repair.svg'
import psFon from '../../images/apple_icon/psFon.png'
import airpodsPro from '../../images/apple_icon/airpodsPro.png'
import airTags from '../../images/apple_icon/airTags.png'
import Swiper from 'swiper';
import SliderHome from './SliderHome';
import BanerHomePage from './BanerHomePage';
import {useSelector, useDispatch} from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import {FaBars,FaTimes} from 'react-icons/fa';
import Helmet from 'react-helmet'
import { userSelector, getProductsSelector, favoritesSelector,  setAuth, setUser, setType, setCategories, setOpensFilter,setAllFilters, authSelector,setCategoryFilter, categoriesSelector } from '../../components/slices/SlicesStore';
function ContentHome() {
    const data = useSelector(getProductsSelector.getProducts)
    const phoneData = data.filter((elem) => elem.type === "iPhone")
    const airpodsData = data.filter((elem) => elem.type === "AirPods")//Нужно в оидельную папку если будет несколько бреднов
    const watchData = data.filter((elem) => elem.type === "Watch")
    const consoleData = data.filter((elem) => elem.type === "PlayStation")
    const acsesData = data.filter((elem) => elem.type=== "Акссесуары")
    const navigate = useNavigate()
    const dispatch = useDispatch()
  return (
    <div>
        <Helmet>
          <title>Agent KEI</title>
          <meta name="twitter:title" content="Agent KEI"/>
          <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
          <meta property="og:title" content="Agent KEI"/>
          <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
          <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
        </Helmet>
        <section className='baner-home-page'>
        <BanerHomePage/>
        </section>

        {/* categories content */}

        <section id='categories'>
            <h1>Выберите то, что вам нужно</h1>
            <div className="container-categorie">
                <div className="categorie grid">
    {/* 1 */}
                    <div onClick = {() => {navigate('/catalog');dispatch(setType(""));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Apple"))}} className="techics">
                        <img id='cat_1' src={iphone} alt="" />
                        <p>Apple</p>
                    </div>
    {/* 2 */}
                    <div onClick = {() => {navigate('/catalog');dispatch(setType(""));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("PC"))}} className="techics">
                        <img id='cat_3' src={pc} alt="" />
                        <p>Компьютеры</p>
                    </div>
                    <div onClick = {() => {navigate('/catalog');dispatch(setType(""));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Dyson"))}}  className="techics">
                        <img id='cat_6' src={dyson_icon} alt="" />
                        <p>Dyson</p>
                    </div>
                    <div onClick = {() => {navigate('/catalog');dispatch(setType(""));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Samsung"))}} className="techics">
                        <img id='cat_2' src={samsung_icon} alt="" />
                        <p>Samsung</p>
                    </div>
                    <div onClick = {() => {navigate('/catalog');dispatch(setType(""));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Консоли"))}} className="techics">
                        <img id='cat_7' src={ps_icon} alt="" />
                        <p>Консоли</p>
                    </div>
                    <div onClick = {() => {navigate('/remont');}} className="techics">
                        <img id='cat_2' src={repair_icon} alt="" />
                        <p>Ремонт</p> 
                    </div>
    {/* 3 */}
    {/* 4 */}
                    {/* <div onClick = {() => {navigate('/catalog');dispatch(setType("Apple Watch"));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Часы"))}} className="techics">
                        <img src={iPad} alt="" />
                        <p>Планшеты</p>
                    </div> */}
    {/* 5 */}

    {/* 6 */}


    {/* 7*/}


    {/* 8 */}


                </div>
            </div>
        </section>

        {/* slider section */}

            {/* apple section */}
        <section id="slider" className='slider_1'>
            <h1>iPhone</h1>
            <div className="container__slider">
                <div className="iphone__slider slider-col">
                    <div className="info__block__iphone">
                        <h3>Овладейте Мощью и Совершенством с <strong>iPhone!</strong></h3>
                        <p>Ключ к совершенству и ворота в будущее технологий.</p>
                        <img src={aiphoneIMG} alt="" />
                    </div>
                    <div className="slider">
                        <SliderHome data={phoneData}/>
                    </div>
                </div>
            </div>
        </section>

            {/* watch section */}
        <section id="slider" className='slider_2'>
            <h1>Watch</h1>
            <div className="container__slider">
                <div className="watch__slider slider-col">
                    <div className="info__block__watch">
                        <div className="">
                        <h3>Ваша Жизнь в Тактах Apple Watch!</h3>
                        <p>Ваш личный тренер, стильный аксессуар и ключ к миру бескрайних перспектив.</p>
                        </div>
                        <div className="img-bottom watch-size">
                        <img src={watchIMG} alt="" />
                        </div>
                    </div>
                    <div className="slider">
                        <SliderHome data={watchData}/>
                    </div>
                </div>
            </div>
        </section>

        {/* Виртуозность Производительности */}
        <section id='options'>
            <div className="two__block">
                <div className="mac__block">
                    <h2>Виртуозность<br></br> <strong>Производительности</strong></h2>
                    <p>Мощь, которая Вас удивит</p>
                    <div className="btn__mac__block">
                        <Link onClick = {() => {dispatch(setType("Готовые сборки"));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Компьютеры"))}} className='sbor__btn' to='/catalog'>В сборе <IoIosArrowForward style={{color: "#fff"}} /></Link>
                        <Link onClick = {() => {dispatch(setType(null));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Компьютеры"))}} className='comlect__btn' to="/catalog">Комплектующие <IoIosArrowForward style={{color: "rgb(191, 72, 0)"}} /></Link>
                    </div>
                    <img src={macPro} alt="" />
                </div>
                <div className="dyson__block">
                    <h2>Невероятная<br></br> <strong>Гибкость</strong></h2>
                    <p>От креативных проектов до рабочих задач</p>
                    <button onClick = {() => {navigate("/catalog");dispatch(setType(null));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Dyson"))}} className="more__btn">Подробнее <IoIosArrowForward style={{color: "#fff"}} /></button>
                    <img src={dyson} alt="" />
                </div>
            </div>
            <div className="playstation">

            </div>
        </section>

        {/* banner playstation */}
        {/* <section id='ps__banner'>
            <div className="ps__block">
                <h2>Погрузитесь в Мир Бесконечных Приключений с <strong>PlayStation!</strong></h2>
                <p>Добро пожаловать в мир, где каждая игра становится эпическим приключением, а каждое виртуальное сражение - испытанием мастерства и стратегии</p>
                <Link style={{display: "flex", color: "rgb(73, 121, 204)"}}>Подробнее <IoIosArrowForward style={{color: "rgb(73, 121, 204)"}}/></Link>
            </div>
        </section> */}


            {/* AirPods section */}
        <section id="slider" className='slider_3'>
            <h1>AirPods</h1>
            <div className="container__slider">
                <div className="airPods__slider slider-col">
                    <div className="info__block__airPods">
                        <img src={airpodsPro} alt="" />
                        <h3>Наслаждайтесь Музыкой Без Границ с AirPods!</h3>
                        <p>Опыт звука, который охватывает вас, как волна музыкальной энергии.</p>
                        {/* <img src={aiphoneIMG} alt="" /> */}
                    </div>
                    <div className="slider">
                    <SliderHome data={airpodsData}/>
                    </div>
                </div>
            </div>
        </section>


        {/* playstation section */}
        <section id="slider" className='slider_4'>
            <h1>PlayStation</h1>
            <div className="container__slider">
                <div className="watch__slider slider-col">
                    <div className="info__block__watch">
                        <div className="">
                        <h3>Мир Приключений с PlayStation!</h3>
                        <p>Место, где каждая игра становится эпическим приключением</p>
                        </div>
                        <div className="img-bottom ps-size">
                        <img src={ps10} alt="" />
                        </div>
                    </div>
                    <div className="slider">
                        <SliderHome data={consoleData}/>
                    </div>
                </div>
            </div>
        </section>

            {/* Аксессуары section */}
        <section id="slider" className='slider_5'>
            <h1>Аксессуары</h1>
            <div className="container__slider">
                <div className="acses__slider slider-col " style={{marginBottom: "6rem"}}>
                    <div className="info__block__acses">
                        <img src={airTags} alt="" />
                        <h3>Не просто Аксессуары. Это Apple.</h3>
                        <p>Поднимите планку своего опыта использования устройств Apple на новый уровень.</p>
                    </div>
                    <div className="slider">
                        <SliderHome data={acsesData}/>
                    </div>
                </div>
            </div>
        </section>

        


    </div>
  )
}

export default ContentHome
