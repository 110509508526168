import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import '../styles/about.scss'
import { Link } from 'react-router-dom'
import { FaTelegram } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoWhatsapp } from "react-icons/io";
import { SlSocialVkontakte } from "react-icons/sl";
import { useLocation,useNavigate } from 'react-router-dom'
import yt from '../images/icons/youtube.svg'
import inst from '../images/icons/instagram.svg'
import tg from '../images/icons/telegram.svg'
import vk from '../images/icons/vk.svg'
import { FaVk } from "react-icons/fa";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Helmet from 'react-helmet';
function About(props) {

    const defaultState = {
        center: [51.665116, 39.194628],
        zoom: 15,
        fullscreenZIndex : 0
      };

    const location = useLocation()
    const param = location.state ? location.state.param : null
    let changePage = param ? param : props.page 
    const navigate = useNavigate()
    const [metaPage, changeMeta] = React.useState("О нас")
    React.useEffect(() => {
            if (changePage == 1){
                changeMeta("О нас")
            }
            if (changePage == 2){
                changeMeta("Доставка и оплата")
            }
            if (changePage == 3){
                changeMeta("Возврат и гарантия")
            }
            if (changePage == 4){
                changeMeta("Ремонт")
            }
            if (changePage == 5){
                changeMeta("Контакты")
            } 
            if (changePage == 6){
                changeMeta("Trade-in")
            } 
       
    }, [changePage])
    
    const handleClick1 = () =>{
        navigate('/about')
    }
    const handleClick2 = () =>{
        navigate('/dostavka-i-oplata')
    }
    const handleClick3 = () =>{
        navigate('/vozvrat-i-garantiya')
    }
    const handleClick4 = () =>{
        navigate('/remont')
    }
    const handleClick5 = () =>{
        navigate('/kontakty')
    }
    const handleClick6 = () =>{
        navigate('/trade-in')
    }

  return (
    <div >
        <Helmet>
          <title>{`Agent KEI | ${metaPage}`}</title>
          <meta name="twitter:title" content={`Agent KEI | ${metaPage}`}/>
          <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
          <meta property="og:title" content={`Agent KEI | ${metaPage}`}/>
          <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
          <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
        </Helmet>
      <Header/>
    <div className="container_info">
    <div className="containerAbout">
                <div className="col-1">
                <div className="info_heade_page ">
                    <h1>Информация</h1>
                    </div>

                </div>

                <div className="col-2">
                    <div className="contend"> <button onClick={handleClick1} className='button_about'><p>О нас</p></button></div>
                    <div className="contend"> <button onClick={handleClick2} className='button_about'><p>Доставка и оплата</p></button></div>
                    <div className="contend"><button onClick={handleClick3} className='button_about'><p>Возврат и гарантия</p></button></div>
                    <div className="contend"><button onClick={handleClick4} className='button_about'><p>Ремонт</p></button></div>
                    <div className="contend"><button onClick={handleClick5} className='button_about'><p>Контакты</p></button></div>
                    <div className="contend"><button onClick={handleClick6} className='button_about'><p>Trade-in</p></button></div>
                    {/* <div className="contend"><button onClick={handleClick6} className='button_about'><p>Договор публичной оферты</p></button></div> */}
                
            </div>
        </div>
        <div className="contaiber-info-page-info">
        {
                        changePage == 1 ? 
                        // О нас
                        <div className="link1 link_contend">
                            <h2>Магазин техники «Agent KEI»</h2>
                                <div className="">
                                    <p>
                                        Мы рады приветствовать вас в магазине техники «Agent KEI»! Мы работаем с 2020 года и предлагаем широкий ассортимент оригинальных устройств от ведущих производителей.<br/>
                                        В нашем магазине вы можете приобрести продукцию таких известных брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров.<br/>
                                        Кроме того, мы предоставляем услуги по ремонту техники Apple и Samsung. Наши опытные мастера быстро и качественно устранят любые неисправности.<br/>
                                        Также мы предлагаем широкий выбор комплектующих для компьютеров и делаем сборки на заказ на любой бюджет. Вы можете выбрать конфигурацию, которая будет соответствовать вашим потребностям и бюджету.<br/><br/>
                                        У нас в продаже большое количество аксессуаров к телефонам, включая чехлы, защитные стёкла, наушники и зарядные устройства. Мы поможем вам подобрать аксессуары, которые будут соответствовать вашим предпочтениям и стилю.<br/>
                                        Мы стремимся предоставить нашим клиентам лучший сервис и высокое качество товаров. Приходите к нам за покупками и услугами!<br/><br/>
                                        Наши преимущества:<br/>
                                        • широкий ассортимент товаров;<br/>
                                        • гарантия качества <br/>
                                        • услуги по ремонту;<br/>
                                        • сборка на заказ;<br/>
                                        • большой выбор аксессуаров.
                                    </p>
                                </div>
                        </div>   
                        
                        : null
                        }
                        {
                        changePage == 2 ? 
                        // Доставка и оплата
                        <div className="link2 link_contend">
                            <h2>Доставка и оплата</h2>
                                 <div className="">
                                        <p>
                                            Оплата в магазине производится наличными/переводом , либо через терминал картой +6%<br/>
                                            Все цены в магазине указаны за наличный расчет .<br/><br/>
                                            Доставка в другой город осуществляется СДЕКом , по полной предоплате .
                                        </p>
                                </div>
                        </div>
                        : null
                        }
                         {
                        changePage == 6 ? 
                        // Доставка и оплата
                        <div className="link2 link_contend">
                            <h2>Trade-in</h2>
                                 <div className="">
                                        <p>
                                        Узнать цену вашего устройства можно написав нам в телеграмм , мы дадим примерную оценку вашего телефона без осмотра по вашему описанию +7 (961) 616-05-93  
                                        <br/>Tg @wovvont
                                        </p>
                                </div>
                        </div>
                        : null
                        }
                        {
                        changePage == 3 ? 
                        // Возврат и гарантия
                        <div className="link3 link_contend">
                            <h2>Возврат и гарантия</h2>
                            <p>
                            Гарантия распространяется на всю технику Apple. <br/>
                            На новую технику мы даём гарантию на обслуживание сроком на один год. <br/>
                            На технику, бывшую в употреблении — от 14 дней до 3 месяцев, в зависимости от типа техники. <br/><br/>
 
                            Возврат или замена возможны в соответствии с законом о защите прав потребителей только для новой и неиспользованной техники.
                            </p>
                        </div>
                        : null
                        }
                        {
                        changePage == 4 ? 
                        // Ремонт
                        <div className="link4 link_contend">
                            <h2>Ремонт</h2>
                            <p>
                            Сервисный центр Agent KEI: ремонт техники Apple, компьютеров, ноутбуков и комплектующих <br/>
 
                            Мы выполняем ремонт любой сложности: от замены аккумулятора до восстановления платы. <br/><br/>
  
                            * Замена аккумулятора — от 2000 рублей. <br/>
                            * Замена дисплея — от 3000 рублей. <br/>
                            * Восстановление платы — от 5000 рублей.<br/>
                            </p>
                        </div>
                        : null
                        }
                        {
                        changePage == 5 ? 
                        // Ремонт
                        <div className="link4 link_contend">
                            <h2>Контакты</h2>
                            <div className="">
                                <div className="contakt_about_container">
                                    <div className="">
                                        <h4>Адрес:</h4>
                                        <p>
                                            <a href="#">Воронеж. Ул. Никитинская 36 </a>
                                        </p>
                                    </div>
                                    <div className="">
                                        <h4>Телефон:</h4>
                                            <p>
                                                 <a href="tel:+79616160593">+7(961)-616-05-93</a>
                                            </p>
                                    </div>
                                    <div className="">
                                        <h4>Часы работы:</h4>
                                        <p>
                                            <a href="#">
                                            ежедневно с 11.00 до 20.00
                                            </a>
                                        </p>
                                    </div>
                                    <div className="">
                                    <h4>Социальные сети</h4>
                                    <div className="social-about_icons">
                                        <div className="">
                                        <a href="https://t.me/wovvont"><FaTelegram style={{fontSize: "25px", color:"#202020"}} /></a>
                                        </div>
                                        <div className="">
                                        <a href="https://www.instagram.com/agent.kei?igsh=Nm53cXhiY3l4azI3"><RiInstagramFill style={{fontSize: "25px", color:"#202020"}} /></a>
                                        </div>
                                        <div className="">
                                        <a href="https://api.whatsapp.com/send?phone=79616160593"><IoLogoWhatsapp style={{fontSize: "25px", color:"#202020"}} /></a>
                                        </div>
                                        <div className="">
                                        <a href="https://vk.com/agent_kei36"><FaVk style={{fontSize: "25px", color:"#202020"}} /></a>
                                        </div>
                                    </div>
                                    </div>

                                </div>
                                <div className="map">
                                       <YMaps >
                                           <Map
                                               width='100%'
                                               height='600px'
                                               zoom='20'
                                               defaultState={defaultState}>
                                                   <Placemark geometry={[51.665116, 39.194628]} />
                                           </Map>
                                       </YMaps>
                                </div>
                            </div>
                        </div>
                        : null
                        }

                
        </div>

    </div>
      {/* <Footer/> */}
    </div>
  )
}

export default About
