import axios from 'axios';
import reduxStore from '../../store/index';
import { setAuth, setUser } from '../slices/SlicesStore';
const $api = axios.create({
    withCredentials: true,
    baseURL: `https://agent-kei.ru/api`, // 'api' to server

})

const { dispatch } = reduxStore;
$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
})
$api.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && originalRequest.url === `/refresh`) { // '/refresh' 
            dispatch(setAuth(false))
            dispatch(setUser(''))
            return Promise.reject(error);
        }
        if (error.response.status == 401 && error.config && !originalRequest._isRetry) {
            originalRequest._isRetry = true;
            try {
                const response2 = await $api.get(`/refresh`, { withCredentials: true })// '/refresh' 
                localStorage.setItem('token', response2.data.accessToken)
                return $api.request(originalRequest);
            } catch (e) {}
        }

        if (error.response.status === 400) {
            return Promise.reject(error);
        }
    });
async function getProducts() {
    var src = await $api.get('https://agent-kei.ru/api/products')
    return src.data
}
export default $api;
export { getProducts };
