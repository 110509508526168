import '../styles/user-cabinet.css'
import { FaUserCircle } from "react-icons/fa";
import Bag from '../images/icons/bag-icons.svg';
import Prof from '../images/icons/user-icons.svg'
import Exit from '../images/icons/exit-icons.svg'
import {useSelector, useDispatch} from 'react-redux'
import {userSelector, setAuth, setUser, authSelector, AllordersSelector,  setFavorites,favoritesSelector, setBusket, getProductsSelector, ordersSelector, getLoading} from '../components/slices/SlicesStore';
import $api from '../components/requests/index'
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import CardUserCabinet from './CardUserCabinet';
import { useEffect, useMemo } from 'react';
import BannerPage from '../components/header/BannerPage'
import { RxExit } from "react-icons/rx";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

import { CiUser } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import { PiBagLight } from "react-icons/pi";
import { PiNotePencilThin } from "react-icons/pi";
import { CiLock } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import Card from '../components/content/products/Card';
import Helmet from 'react-helmet'
export default function UserCabinet () {
    function checkIfInJson(obj, arr, tip){
        for (let i= 0; i < arr.length; i++){
          if (arr[i][`${tip}`] == obj){
            return true
          }
        }
        return false
    
      }
    const useInit = (callback, depends = []) => useMemo(callback, depends)

      const favoriteData = useSelector(favoritesSelector.getFavorites)
    const isLoading = useSelector(getLoading.getIsLoading)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(userSelector.getUser)
    const products = useSelector(getProductsSelector.getProducts)
    const orders = useSelector(ordersSelector.getOrders)
    const [data_orders, setDataOrders] = useState([])
    useEffect(() => {
        let temp = []
        if (orders) {
            console.log(orders)
            orders.map((elemOrder) => {
                let temp2 = []
                elemOrder.products.map((elemOne) => {
                 products.map((elem) => {
                    if (elem.id == elemOne.id){
                        let tempProduct = Object.assign({}, elem, elemOne);
                        temp2.push(tempProduct)
                    }
                    })
                })
                temp.push({products: temp2, description: elemOrder.description, cost: elemOrder.cost})
            })
            console.log(temp)
            setDataOrders(temp)
        }
        
    }, [isLoading]) 


    const handleLogout = () => {
       $api.get('/logout')
       .then(function(e) {
        console.log(e)
         dispatch(setAuth(false))
         dispatch(setUser(''))
         if (localStorage.getItem("favorite")){
        
            let data = JSON.parse(localStorage.getItem("favorite"))
            dispatch(setFavorites(data))
        } else {
            dispatch(setFavorites([]))
        }
        if (localStorage.getItem("busket")){
        
            let data = JSON.parse(localStorage.getItem("busket"))
            dispatch(setBusket(data))
        } else {
            dispatch(setBusket([]))
        }
         navigate('/')
       })
      }
      //для смены на страничке раздела профиль на раздел заказы
    const [profile,setProfile] = React.useState(true);
    //console.log(profile,'profile')
    const [zakaz,setZakaz] = React.useState(false);
    // const [toSave, setToSave] = React.useState();
    //console.log(zakaz,'zakaz')
    const [name, setNameChange] = React.useState(null)
    const [phone, setPhoneChange] = React.useState(null)
    const [gender, setGender] = React.useState(null)
    const [email, setEmailChange] = React.useState(null)
    const [password1, setPassword1] = React.useState(null)
    const [birth, setBirth] = React.useState(null)
    const [password2, setPassword2] = React.useState(null)
    const [incor, setIncor] = React.useState(null)
    const [cor, setCor] = React.useState(null)
    useEffect(() => {

        if ((user.gender === '' || user.gender === "Male") && gender === '' && profile){
            setGender("Male")
            document.querySelectorAll('.man-btn')[0].setAttribute("style", "border: #000 4px solid;");
            document.querySelectorAll('.man-btn')[1].setAttribute("style", "border: #000 4px solid;");
            document.querySelectorAll('.man-btn')[2].setAttribute("style", "border: #000 4px solid;");
        } else if(gender === '' && user.gender === "Female" && profile) {
            document.querySelectorAll('.women-btn')[0].setAttribute("style", "border: #000 4px solid;");
            document.querySelectorAll('.women-btn')[1].setAttribute("style", "border: #000 4px solid;");
            document.querySelectorAll('.women-btn')[2].setAttribute("style", "border: #000 4px solid;");
        }
    })
    const changeZakazPage = () => {
        setZakaz(true);
        setProfile(false);
    }
    const changeProfilePage = () => {
        setProfile(true);
        setZakaz(false);
    }
    const handleChange = (e) => {
        if(e.target.id == 'email'){
            setEmailChange(e.target.value)
        } else if (e.target.id == 'name'){
            setNameChange(e.target.value)
        } else if (e.target.id == 'phone'){
            setPhoneChange(e.target.value)
        }else if (e.target.id == 'password1'){
            setPassword1(e.target.value)
        }else if (e.target.id == 'password2'){
            setPassword2(e.target.value)
        } else if (e.target.id == 'birth'){
            setBirth(e.target.value)
        }
        // setToSave(true)
    }
    const changeUser = (e) => {
        e.preventDefault();
        $api.post(`/user/changes`, {userid: user.userid, email, phone, name, password1, password2})
        
        .then(function(e) {
                setCor('Данные успешно изменены')
                setIncor("")
                

        })
        .catch(function (error) {
            setIncor(error.response.data)
            setCor("")
      
    
        })
    }
    const [visible, setVisible] = React.useState(false);
    const handleVisible = () =>{
        setVisible(!visible);
    }
    const[newVisible, setNewVisible] = React.useState(false);
    const hadleNewVisible = () => {
        setNewVisible(!newVisible)
    }


    const user__block = document.getElementById("user__block");

    const [datamodels, setDatamodels] = useState(false)
    const changeDataModels = () => {
        setDatamodels(!datamodels);
        setPasswordModals(false)
        setIncor('')
        setCor('')
        // user__block.style.display = "none"
        // document.getElementById("#user__block").style.display = "none";
    }

    const [passwordModals,setPasswordModals] = useState(false);
    function changePasswordModals(){
        setPasswordModals(!passwordModals)
        setDatamodels(false)
        setIncor('')
        setCor('')
        // user__block.style.display = "none"
        // document.getElementById("change__models__block").style.position = "absolute"
        // document.getElementById("#user__block").style.display = "none";
    }
    console.log(data_orders)
    return(    
        <div className="user__page__container">
        <Helmet>
          <title>Agent KEI | Личный кабинет</title>
          <meta name="twitter:title" content="Agent KEI | Личный кабинет"/>
          <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
          <meta property="og:title" content="Agent KEI | Личный кабинет"/>
          <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
          <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
        </Helmet>
            <div className="info_heade">
                <div className="user-main-block">
                <h1>Личный кабинет</h1>
                </div>
          </div>
            {
                datamodels ? 
                <div className='blur__modals'>
                    <section id='change__models__block'>
                        <button className='close__modal__change' onClick={changeDataModels}><IoMdClose style={{fontSize: "30px"}}/></button>
                        <h2>Изменение личных данных</h2>
                        <form action="">
                            <div className="email">
                                <p>E-mail</p>
                                <input onChange={handleChange} type='email' id="email"  placeholder={user.email}></input>
                            </div>
                            <div className="name">
                                <p>Имя</p>
                                <input onChange={handleChange} type='text' id="name"  placeholder={user.name}></input>
                            </div>
                            <div className="number">
                                <p>Телефон</p>
                                <input onChange={handleChange} type='text' id="phone"  placeholder={user.phone}></input>
                            </div>
                            <p>{incor}</p>
                            <p>{cor}</p>
                            <button className='save__button' onClick={(e) => changeUser(e)}>Сохранить</button>
                            
                        </form>
                    </section>
                </div>
                 : 
                ""
                
            }
            {
                passwordModals ?
                <div className='blur__modals'>
                    <section id='change__models__block'>
                        <button className='close__modal__change' onClick={changePasswordModals}><IoMdClose style={{fontSize: "30px"}}/></button>
                        <h2>Изменения пароля</h2>
                        <form action="">
                            <div className="email">
                                <p>Новый пароль</p>
                                <input onChange={handleChange} type='password' id="password1"  placeholder='* * * * * * * * * * * * * * * * '></input>
                            </div>
                            <div className="name">
                                <p>Подтвердите пароль</p>
                                <input onChange={handleChange} type='password' id="password2"  placeholder='* * * * * * * * * * * * * * * * '></input>
                            </div>
                            <p>{incor}</p>
                            <p>{cor}</p>
                            <button className='save__button' onClick={(e) => changeUser(e)}>Сохранить</button>
                            
                        </form>
                    </section>
                </div>
                 : 
                ""
            }
            <section id='user__block'>
                    <div className="user__info__block one">
                    <div className="naming__block">
                        <div className="user-main-block">
                        <CiUser style={{color : "rgba(191, 72, 0, 1)",fontSize : "20px"}}/>
                        <h3>Личные данные</h3>
                        </div>
                    </div>
                    <div className="info__block">
                        {
                            user.name ? <p>{user.name}</p> : ""
                        }
                        {
                            user.email ? <p>{user.email}</p> : ""
                        }
                        {
                            user.phone ? <p>{user.phone}</p> : ""
                        }
                        
                        
                        
                    </div>
                    <div className="change__block">
                        <div onClick={changeDataModels}>
                            <PiNotePencilThin style={{color : "rgba(191, 72, 0, 1)",fontSize : "15px"}}/>
                            <p>Изменить</p>
                        </div>
                        <div onClick={changePasswordModals}>
                            <CiLock style={{color : "rgba(191, 72, 0, 1)",fontSize : "15px"}}/>
                            <p>Изменить пароль</p>
                        </div>
                    </div>
                    </div>

                    <div className="user__info__block three ">
                    <div className="naming__block">
                        <div className="user-main-block">
                            <PiBagLight style={{color : "rgba(191, 72, 0, 1)",fontSize : "20px"}}/>
                            <h3>История заказов</h3>
                        </div>
                        {data_orders.map((elem) => {
                        return (
                        <div className='one-order'>
                            {elem.products.map((obj) => {
                                return (
                                <div className='product-one'>
                                    <p><span>Название: </span> {obj.name}</p>
                                    <p><span>Цена: </span> {obj.price}</p>
                                </div>
                            )
                            })}
                                <div className="">
                                        <p><span>Общая цена: </span> {elem.cost}</p>
                                        {/* <p> <span>Описание  заявки: </span>{elem.description}</p> */}
                                </div>
                            </div>
                            )
                        })}
                    </div>
                    </div>

                    {/* <div className="user__info__block">
                    <div className="naming__block">
                        <CiHeart style={{color : "rgba(191, 72, 0, 1)",fontSize : "20px"}}/>
                        <h3>Избранное</h3>
                    </div>
                    <div className="info__block">
                        {
                            favoriteData.length < 1 ? <p>Вы не добавили ни одного товара в Избранные</p> : <p>В избранном {favoriteData.length}</p>
                        }
                    </div>
                    </div> */}

                    <div className="user__info__block logout__mobile two" >
                    <div className="naming__block">
                        <div className="user-main-block">
                        <CiLogout style={{fontSize : "20px"}}/>
                        <h3>Выйти</h3>
                        </div>
                    </div>
                    <div className="logout__block">
                        <div className='logout' onClick={handleLogout}>
                            <button>Выйти</button>
                        </div>
                    </div>
                    </div>

                </section>
            
        </div>
        
        
    )
}
