import React from 'react'
import vk from '../images/vk-icon.png'
import '../styles/user-page.css'
import * as VKID from '@vkid/sdk';
function Forms({handleEmail, handleType, handleCorIncor}) {
    const [social, setSocial]= React.useState(false);
    const handleVk = () =>{
        if (!social){
            setSocial(!social)
        }
        
        handleType('vk')
    }
    VKID.Config.set({
        app: 51819507, // Идентификатор приложения.
        redirectUrl: 'https://streetcube.ru/vk_auth', // Адрес для перехода после авторизации.
      });
      const handleLogin = () => {
        // Открытие авторизации.
        VKID.Auth.login()
      }
      const [email,setEmail] = React.useState(false);
      const [number ,setNumber] = React.useState(false);
    //   const [socialSeti,setSocialSeti] = React.useState(false);
    const handleEmailSmena = () => {
        setEmail(true);
        setNumber(false);
        setSocial(false);
        handleType(null)
        handleCorIncor()
    }

    
    return(
        <>
            {/* <div className='form-smena'>
                <button onClick={handleEmailSmena}>Эл.почта</button>
                <button>Телефон</button>
                <button onClick={handleVk}>Соц.сети</button>
            </div> */}
            {!social ? 
            <div className='input-email'>
                <p>Email</p>
                <input type="email" onChange={handleEmail} placeholder='введите email...'/>
            </div>
            : <><button className='vk-btn' onClick={handleLogin}>Вконтакте</button></>}
        </>
        )
}

export default Forms