import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import '../styles/user-page.css'
import {userSelector, setAuth, setUser, authSelector} from '../components/slices/SlicesStore';
import {useSelector, useDispatch} from 'react-redux'
import Login from "./Login"
import UserCabinet from './UserCabinet';
function UserPage() {
    const isAuth = useSelector(authSelector.getAuth)
  return (
    <div className='user-page-container'>
      {isAuth ? <UserCabinet/> : <Login/>}
    </div>
  )
}

export default UserPage