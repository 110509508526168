import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './footer.scss'
import yt from '../../images/icons/youtube.png'
import inst from '../../images/icons/instagram.png'
import tg from '../../images/icons/telegram.png'
import vk from '../../images/icons/vk.png'
import { FaTelegram } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoWhatsapp } from "react-icons/io";
import { SlSocialVkontakte } from "react-icons/sl";
import {useSelector, useDispatch} from 'react-redux'
import { FaVk } from "react-icons/fa";

import { userSelector, getProductsSelector, setAuth, setUser, setType, setCategories, setOpensFilter,setAllFilters, authSelector,setCategoryFilter, categoriesSelector } from '../slices/SlicesStore';
function Footer() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const categories = useSelector(categoriesSelector.getCategories)
  return (
    <footer>
        <div className="footer__container__grid">
            <div className="column__1">
                <h4>Информация</h4>
                <ul>
        
                    <li><Link to="/about">О компании</Link></li>
                    <li><Link to="/dostavka-i-oplata">Доставка и оплата</Link></li>
                    <li><Link to='/vozvrat-i-garantiya'>Возврат и гарантия</Link></li>
                    <li><Link to='/remont'>Ремонт</Link></li>
                    <li><Link to='/kontakty'>Контакты</Link></li>
                    <li><Link to='/policy'>Политика конфиденциальности</Link></li>
                </ul>
            </div>
            <div className="column__2">
                <h4>Каталог</h4>
                <ul>
                {categories.map((elem) => <li onClick={() => {navigate('/catalog');dispatch(setType(null));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter(elem))}}>{elem}</li>)}
                </ul>
            </div>

            <div className="column__4">
            <h4>Контакты</h4>
                <ul>
                    <li><a href="tel:+79616160593">+7 ( 961 ) 616-05-93</a></li>
                    <li><a href="https://yandex.ru/profile/192851336232">Никитинская ул., 36, Воронеж</a></li>

                </ul>
            </div>
            <div className="column__5">
                <h4>Социальные сети</h4>
                <div className="social-footer_icons">
                    <div className="">
                    <a href="https://t.me/wovvont"><FaTelegram style={{fontSize: "25px", color:"#fff"}} /></a>
                    </div>
                    <div className="">
                    <a href="https://www.instagram.com/agent.kei?igsh=Nm53cXhiY3l4azI3"><RiInstagramFill style={{fontSize: "25px", color:"#fff"}} /></a>
                    </div>
                    <div className="">
                    <a href="https://api.whatsapp.com/send?phone=79616160593"><IoLogoWhatsapp style={{fontSize: "25px", color:"#fff"}} /></a>
                    </div>
                    <div className="">
                    <a href="https://vk.com/agent_kei36"><FaVk style={{fontSize: "25px", color:"#fff"}} /></a>
                    </div>
                </div>
                <div>
                    <p className="polit_footer">Любая представленная информация<br/> не является публичной</p>
                 </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer
