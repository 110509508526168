import React from 'react'
import $api from '../components/requests/index'
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";
import {useDispatch} from 'react-redux'
import {setAuth, setUser, setFavorites, setProducts, setBusket, setOrders} from '../components/slices/SlicesStore';
import Header from '../components/header/Header';
import '../styles/vk-login.css'

function VkLogin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoad, setLoading] = React.useState(true)
    const [data, setData] = React.useState(null)
    const [password, setPassword] = React.useState('')
    const [incor, setIncor] = React.useState(null)
    let vk_user = queryString.parse(window.location.search)
    React.useEffect(() => {
        if (vk_user.payload){

        $api.post(`/vk_auth`, {user: JSON.parse(vk_user.payload)})
        .then(function(response) {
            if (!response){
                // navigate("/");
            } else {

            
                setData(response.data)

                if (response.data.status_code === 1){
                    navigate("/");//ну короче сюда, на локалке вк не рабоатет, на сервак зальем и норм
                    dispatch(setUser(response.data.userData))
                    dispatch(setAuth(true))
                    dispatch(setFavorites(response.data.liked))
                    dispatch(setBusket(response.data.busket))
                    dispatch(setOrders(response.data.orders))
                } else if (response.data.status_code === 3){
                    setIncor(response.data.message)
                }
                setLoading(false)
            }
            })
            
        }
      }, [vk_user.payload]);
  
    const handlePassword = (e) => {
        setPassword(e.target.value)
    } 
    const handleChangeVkTo = () => {
        $api.post('/vk_to_account', {userid: data.userid, vk_id: data.vk_id, password: password, vk_user: data.vk_user})
        .then((response) => {
            navigate("/");
            dispatch(setUser(response.data.userData))
            dispatch(setAuth(true))
            dispatch(setFavorites(response.data.liked))
            dispatch(setBusket(response.data.busket))
            dispatch(setOrders(response.data.orders))
        })
        .catch((e) => setIncor(e.response.data))
    }
    const handleReset = (e) =>{
        navigate('/reset_password')
    }

    return(
        <>
            {isLoad ? <p>loading</p> :
            
            <>
            {data.status_code === 2 ?
            <div className="vk">
                <div className="vk-container">
                    <h1>Привязка VK к аккаунту</h1>
                    <p className='privyazka-vk'>Чтобы привязать аккаунт вк, к существующему аккаунту, введите пароль</p>
                    <div className="vk-password-privyaz">
                        <label>Введите пароль</label>
                        <input placeholder='* * * * * * * * * * * * * * * ' onChange={handlePassword}></input>
                    </div>
                    <button onClick={handleReset}>Забыли пароль?</button>
                    <p className='incorrect'>{incor}</p>
                    <button className='privyazka-password' onClick={handleChangeVkTo}>Привязать</button>
                    
                </div>
            </div>

            :
            <></>
            }
            {data.status_code === 3 ?
            <>
            <div className="vk">
                <div className="vk-container">
                    <h1>Привязка VK к аккаунту</h1>
                    <p className='message'>{incor}</p>
                    <button className='return-site' onClick={() => navigate('/')}>Вернуться на сайт</button>
                </div>
            </div>

            </>
            :
            <></>
            }
            </>
            
            
            }
        </>
        )
}

export default VkLogin