
import React, { useEffect } from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import '../styles/user-page.css'
import $api from '../components/requests/index'
import { useNavigate } from "react-router-dom";
import {useDispatch} from 'react-redux'
import {setAuth, setUser, setFavorites, setProducts, setBusket, setOrders} from '../components/slices/SlicesStore';
import Forms from "./Forms"
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import Helmet from 'react-helmet'

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordCheck, setPasswordCheck] = React.useState('');
    const [loginOrReg, setLoginOrReg] = React.useState(null);
    const [incorrect, setIncor] = React.useState('');
    const [correct, setСor] = React.useState('');
    const [name,setName] = React.useState("");
    const [number,setNumber] = React.useState("");

    const handleName = (event) => {
        setName(event.target.value)
    }
    const handleNumber = (event) => {
        setNumber(event.target.value);
    }

    const handleEmail = (e) =>{
        setEmail(e.target.value)
    }
    const handleChangeType = (e) =>{
        setLoginOrReg(e)
    }
    const handleRepeat = () => {
        $api.post('/repeatActivation', {email: email}); 
        setIncor('');
        setСor("Вам было повторно отправлено письмо с активацией")
    }
    const handlePassword = (e) =>{
        setPassword(e.target.value)
    }
    const handlePasswordCheck = (e) =>{
        setPasswordCheck(e.target.value)
    }
    const handleReset = (e) =>{
        navigate('/reset_password')
    }
    const checkRegist = () => {
        if (password === passwordCheck){
            $api.post(`/registration`, { email: email,password: password,name: name,phone: number })
            .then(function(response) {
                navigate("/");
                dispatch(setUser(response.data.userData))
                dispatch(setAuth(true))
                dispatch(setFavorites(response.data.liked))
                dispatch(setBusket(response.data.busket))
                dispatch(setOrders(response.data.apply))
    
        })
        .catch(function (error) {
            setIncor(error.response.data.message)
            setСor('')
        })
        } else {
            setIncor("Пароли не совпадают")
            setСor('')
        }

    }
    const checkEmail = () => {
        $api.post(`/auth`, {login: email})
        .then(function(e) {
            if (e.data === "Invalid email or password"){
                setIncor('Введите корректный адрес электронной почты')
                setСor('')
            } else {
                setLoginOrReg(e.data)
                setIncor('')
            }
            
       })
       .catch(function (error) {
        setIncor(error.response.data.message)
        setСor('')
      
    
      })
    }
    const checkLogin = async () =>{
        const response = await $api.post(`/login`, {login: email, password})
        .then(function(response) {
            navigate("/");
            dispatch(setUser(response.data.userData))
            dispatch(setAuth(true))
            dispatch(setFavorites(response.data.liked))
            dispatch(setBusket(response.data.busket))
            dispatch(setOrders(response.data.orders))
   })
   .catch(function (error) {
        setIncor(error.response.data.message)
        
        if ('Ваш аккаунт не активирован' == error.response.data.message){
            setСor(<button onClick={handleRepeat}>Отправить письмо повторно</button>)
        }
    })
    }
    useEffect(() => {
            let form = document.querySelector(".form-container");
            let button = document.querySelector(".auth");
            form.addEventListener("keypress", function(event){
            if(event.key === "Enter"){
            event.preventDefault();
            button.click();
        }
    })
    })
   const [visible, setVisible] = React.useState(false);
    const handleVisible = () =>{
        setVisible(!visible);
    }
    const [newvisible, newSetVisible] = React.useState(false);
    const newHandleVisible = () =>{
        newSetVisible(!newvisible);
    }
    const handleCorIncor = () => {
        setIncor('')
        setСor('')
    }
    
  return (

    <div className='user-page-container'>
        <Helmet>
          <title>Agent KEI | Личный кабинет</title>
          <meta name="twitter:title" content="Agent KEI | Личный кабинет"/>
          <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
          <meta property="og:title" content="Agent KEI | Личный кабинет"/>
          <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
          <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
        </Helmet>
        <div className='form-container'>
            <h1>Вход в личный аккаунт</h1>

            <Forms handleEmail={handleEmail} handleType={handleChangeType} handleCorIncor={handleCorIncor}/>
            {!loginOrReg ? <button onClick={handleReset}>Забыли пароль?</button> : null}
            <div className="password">
                
            </div>
            {loginOrReg === 'login' ? 
            <>
            <div className='input-password'>
                <p>Пароль</p>
                {
                    visible ? 
                    <div className="pass-visible">
                        <input type="text"onChange={handlePassword} placeholder='* * * * * * * * * * * * * * * * '/>
                        <AiOutlineEye onClick={handleVisible}/>
                    </div>: 
                    <div className="pass-invisible">
                        <input type="password" onChange={handlePassword} placeholder=' * * * * * * * * * * * * * * * *'/>
                        <AiOutlineEyeInvisible onClick={handleVisible}/>
                    </div>
                }            
		    </div>
            
            <p className='incorrect'>{incorrect}</p>
            <p className='correct'>{correct}</p>
            <button onClick={handleReset}>Забыли пароль?</button>
            <button onClick={checkLogin} className='auth'>Вход/авторизация</button>
            
            
            </>
            : 
            null}
            
            {loginOrReg === 'reg' ? 
            <>

            <div className='input-password'>
                <p>Пароль</p>
                {
                    visible ? 
                    <div className="pass-visible">
                         <input maxLength="16" type="text"onChange={handlePassword} placeholder='* * * * * * * * * * * * * * * * *'/>
                         <AiOutlineEye onClick={handleVisible}/>
                    </div>:
                    <div className="pass-invisible">
                        <input maxLength="16" type="password"onChange={handlePassword} placeholder='* * * * * * * * * * * * * * * * *'/>
                         <AiOutlineEyeInvisible onClick={handleVisible}/>
                    </div>
                }
                
            </div>
            <div className='input-password'>
                <p>Повторите пароль</p>
                {
                    newvisible ?  
                    <div className="pass-visible">
                        <input maxLength="16" type="text" onChange={handlePasswordCheck} placeholder='* * * * * * * * * * * * * * * * *'/>
                        <AiOutlineEye onClick={newHandleVisible}/>
                    </div>:
                    <div className="pass-invisible">
                        <input maxLength="16" type="password"onChange={handlePasswordCheck} placeholder='* * * * * * * * * * * * * * * * *'/>
                         <AiOutlineEyeInvisible onClick={newHandleVisible}/>
                    </div>

                }
            </div>
            
            <div className="input__name">
                <p>ФИО</p>
                <input type="text" onChange={handleName} placeholder='Иван Иванович Иванов'  />
            </div>
            
            <div className="input__phone">
                <p>Телефон</p>
                <input type="text" onChange={handleNumber} placeholder='+7-(999)-999-99-99'  />
            </div>
            
            <p className='incorrect'>{incorrect}</p>
            <p className='correct'>{correct}</p>
            <button onClick={checkRegist}className='auth'>Вход/авторизация</button>
            
            </>
            : 
            null}
            {!loginOrReg && loginOrReg != "vk" ?
            <>

            <p className='incorrect'>{incorrect}</p>
            <p className='correct'>{correct}</p>
            <button onClick={checkEmail} className='auth'>Вход/авторизация</button> 
            </> 
            
            : null}
        </div>

    </div>
  )
}

export default Login