// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import Card from '../content/products/Card';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default function SwiperSlider({data,type}){
  console.log(data)
  return (
    <Swiper
    
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={4}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        375 : {
          slidesPerView : 1.5,
          spaceBetween : 15
        },
        430 : {
          slidesPerView : 1.5,
          spaceBetween : 15
        },
        768 : {
          slidesPerView : 2.5,
          spaceBetween : 30
        },
        1025 : {
          slidesPerView : 3.7,
          spaceBetween : 30
        }
    }}
    >
        {data.slice(0,15).map((obj,index) => 
            <SwiperSlide>
                <Card              
                    id={obj.id}
                    key={index}
                    name={obj.name}
                    newPrice={obj.newPrice}
                    // prevPrice={obj.prevPrice}
                    // size={obj.size}
                    // sizes = {obj.sizes}
                    image_url={obj.imageUrl}
                    // imgCount={obj.imgCount}
                    countProduct={true}
                />
            </SwiperSlide>
        )}
      
    </Swiper>
  );
};