import React, { useEffect, useState } from 'react'
import './sidebar.scss'
import strelka from '../../../images/icons/strelka.svg'
import WS from '../../../images/icons/white-strelka.svg'
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import {categorySelector, markSelector, typeSelector, allFiltersSelector, opensFilterSelector, setOpensFilter} from '../../slices/SlicesStore';
function Sidebar({handleChange, filters, headphonesMark, actual_filters, handleClose, handleChange2, handleReset2 ,handleFilterOpend, toApply}) {
    const dispatch = useDispatch()
    function translit(word){
        var converter = {
          'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
          'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
          'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
          'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
          'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
          'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
          'э': 'e',    'ю': 'yu',   'я': 'ya'
        };
       
        word = word.toLowerCase();
        
        var answer = '';
        for (var i = 0; i < word.length; ++i ) {
          if (converter[word[i]] == undefined){
            answer += word[i];
          } else {
            answer += converter[word[i]];
          }
        }
       
        answer = answer.replace(/[^-0-9a-z]/g, '-');
        answer = answer.replace(/[-]+/g, '-');
        answer = answer.replace(/^\-|-$/g, ''); 
        return answer;
      }
    const mark = useSelector(markSelector.getMark)
    const navigate = useNavigate()
    const openFilters = useSelector(opensFilterSelector.getOpensFilter)
    const allFilters = useSelector(allFiltersSelector.getAllFilters)
    const handleOpenFilter = (e) =>{
        
        console.log(e.target)
        let temp = openFilters
        let classFilter = ''
        if (!e.target.getAttribute("class")){
            classFilter = e.target.parentElement.className
        } else {
            classFilter = e.target.getAttribute("class").split(" ")[1]
        }
       
        if (!openFilters.includes(classFilter)){
            dispatch(setOpensFilter([
                ...openFilters,
                classFilter
            ]))
        } else {
            dispatch(setOpensFilter(openFilters.filter((elem) => elem !== classFilter)))
        }
    }
    const type = useSelector(typeSelector.getType)
    
    const usedCategory = useSelector(categorySelector.getCategory)
    function compareNumbers(a, b) {
        return Number(a) - Number(b);
      }

    console.log(actual_filters, allFilters)
    useEffect(() => {
        for (let i = 0; i < allFilters.length; i++){
            let sidebar_buttons = document.querySelectorAll(`.button_for.${translit(allFilters[i].name)}`)
            console.log(sidebar_buttons)
            for( let j = 0; j < sidebar_buttons.length; j++){
              console.log(sidebar_buttons[j].textContent, allFilters[i].value, sidebar_buttons[j].textContent == allFilters[i].value)
              if (sidebar_buttons[j].textContent == allFilters[i].value)
                sidebar_buttons[j].style.color= 'tomato';
      
            }
          }
    }, [allFilters])
    useEffect(() => {
        // let doc = document.getElementById("sidebar__block")
        // if (!actual_filters){
            
        //     doc.style.width = 0
        // } else {
        //     doc.style.width = null
        // }

        
    }, [actual_filters])
  return (
    <>
       
        <div className='sidebar-container'>
        
        <h2 className='title_sidebar_filter'>Фильтрация</h2>
        
            {actual_filters ? 
            <>
            
                {
                    actual_filters.map((elem) =><> 
                    <div className="catalogs">
                    <div onClick={handleOpenFilter} className={`catal-btn ${translit(elem.name)}`}>  
                    <h2>{elem.name}</h2> 
                    {
                        openFilters.includes(translit(elem.name)) ? <MdOutlineKeyboardArrowUp className={`arrow ${translit(elem.name)}`} />  : <MdOutlineKeyboardArrowDown className={`arrow ${translit(elem.name)}`} />
                    }
                    </div>  
                    {openFilters.includes(translit(elem.name)) ?
                    <ul>
                    {
                        Array.isArray(elem.value) && elem.value.length != 1 ? 
                        <>
                            {elem.value.map((filt) => 
                                <li><button className={`button_for ${translit(elem.name)}`} onClick={handleChange}>{filt}</button></li>
                            )}
                        </>
                        : 
                        <li><button style={{'color': "tomato"}}className={`button_for1 ${translit(elem.name)}`} onClick={handleChange}>{elem.value}</button></li>
                    }

                    </ul>: null
                    }
                    
                    
                    
                    </div>
                    </> )
                }
                {allFilters.length ? 
                <div className='apply-reset'>
                    <button className='apply-filters' onClick={toApply}>Применить</button>
                    <button className='reset-button' onClick={handleReset2}>Сброс</button>
                </div>
                
                
                : null}


            </>
            :
            null
            }
        </div>
                
        {/* <div className='sidebar-container-tablet'>
            <div className='blur-container' tabindex='1' onClick={(e) => handleChange2()}>
            <div className='blur'></div>
            <div className="sidebar-content" onClick={(e) => e.stopPropagation(e)}>
                <div className="first-line-sidebar-tablet">
                    <div>
                        <h1>Фильтрация</h1>
                        <p onClick={handleFilterOpend}>Закрыть</p>
                    </div>
                    <div className="line-first"></div>
                </div>
                <div className="catalogs">
                <div className="catal-btn" onClick={pokazCatalogHeandler}>
                        <h2>Серия</h2>
                        {
                            pokazCatalog  === true ? <MdOutlineKeyboardArrowUp className='arrow' />  : <MdOutlineKeyboardArrowDown className='arrow' />
                        }
                </div>
                    {pokazCatalog === true ?
                        <ul>
                <li><button className='category'  onClick={handleChange}>iPhone 11</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 11 pro</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 11 pro max</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 12</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 12 pro</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 12 pro max</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 13</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 13 pro max</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 14</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 14 pro</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 14 pro max</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 15</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 15 pro</button></li>
                <li><button className='category' onClick={handleChange}>iPhone 15 pro max</button></li>

                        </ul> : null
                    }
                </div>

                <div className="brend">
                    <div className="line"></div>
                    <div onClick={pokazBrendHeandler} className="brn-btn">
                        <h2>Цвет</h2>
                        {
                            pokazBrend === true ? <MdOutlineKeyboardArrowUp className='arrow' />  : <MdOutlineKeyboardArrowDown className='arrow' />
                        }
                    </div>
                    {pokazBrend === true ?
                            <ul>
                                <li><button className='brand' onClick={handleChange}>Синий</button></li>
                                <li><button className='brand' onClick={handleChange}>Розовый</button></li>
                                <li><button className='brand' onClick={handleChange}>Зеленый</button></li>
                                <li><button className='brand' onClick={handleChange}>Чёрный</button></li>
                                <li><button className='brand' onClick={handleChange}>Белый</button></li>
                                <li><button className='brand' onClick={handleChange}>Золотой</button></li>
                                <li><button className='brand' onClick={handleChange}>Жёлтый</button></li>
                                <li><button className='brand' onClick={handleChange}>Голубой</button></li>
                                <li><button className='brand' onClick={handleChange}>Фиолетовый</button></li>
                                <li><button className='brand' onClick={handleChange}>Тайна</button></li>
                            </ul>: null
                    }
                </div>

                <div className="prices">
            <div className="line"></div>
                </div>
                <div className="prices">
                    <div className="line"></div>
                    <div onClick={pokazPriceHeandler} className="price-btn">
                        <h2>Объём памяти</h2>
                        {
                            pokazBrend === true ? <MdOutlineKeyboardArrowUp className='arrow' />  : <MdOutlineKeyboardArrowDown className='arrow' />
                        }
                    </div>
                    {pokazPrice === true ?
                            <ul>
                               <li><button className='price' type="checkbox" onClick={handleChange}>64</button></li>
                                <li><button className='price' onClick={handleChange}>128 GB</button></li>
                                <li><button className='price' onClick={handleChange}>264 GB</button></li>
                                <li><button className='price' onClick={handleChange} >512 GB</button></li>
                                <li><button className='price' onClick={handleChange}>1 TB</button></li>
                                <li><button className='price' onClick={handleChange}>2 TB</button></li>
                            </ul>: null
                    }
                </div>
            </div>
            </div>
        </div> */}

    </>
    
  )
}

export default Sidebar;