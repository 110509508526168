import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import '../styles/errorpage.css'
import { Link } from 'react-router-dom'

function ErrorPage() {
  return (
    <div className='error-container'>
      <Header/>
      <div className="er-container">
        <p>404</p>
        <Link className='link' to='/'>Назад в магазин</Link>
      </div>
      <Footer/>
    </div>
  )
}

export default ErrorPage