import { createSlice } from '@reduxjs/toolkit'
import {current } from '@reduxjs/toolkit'

export const storeSlice = createSlice({
  name: 'user',
  initialState: {
    isAuth: false,
    user: null,
    productToView: 12,
    busket: [],
    favorites: [],
    products: [],
    orders: [],
    isLoading: true,
    searchInput: false,
    vk_auth: '',
    orders_data: null,
    exFilter: null,
    colorFilter :null,
    markFilter : null,
    memoryFilter:null,
    seriaFilter:null,
    categoryFilter: "Apple",
    razmFilter: null,
    scrollPosition: 0,
    loading: true,
    opensFilter: [],
    categories: [],
    types: [],
    type: null,
    allFilters: [],
    actual_filters: true
  },

  reducers: {
    setMark : (state, action) => {
      state.markFilter = action.payload
    },
    setActual: (state, action) =>{
      state.actual_filters = action.payload
    }, 
    setOneTypes: (state, action) => {
      state.types = action.payload
    },
    setAllFilters : (state, action) => {
      state.allFilters = action.payload
    },
    setType : (state, action) => {
      state.type = action.payload
    },
    setCategories : (state, action) => {
      state.categories = action.payload
    },
    setOpensFilter: (state, action) =>{
      state.opensFilter = action.payload
    }, 
    setScroll: (state, action) => {
      state.scrollPosition= action.payload
    },
    setExFilter: (state, action) => {
      state.exFilter = action.payload
    },
    setSeriaFilter: (state, action) => {
      state.seriaFilter = action.payload
    },
    setColorFilter: (state, action) => {
      state.colorFilter = action.payload
    },
    setMemoryFilter: (state, action) => {
      state.memoryFilter = action.payload
    },
    setRazmFilter: (state, action) => {
      state. razmFilter = action.payload
    },
    setCategoryFilter: (state, action) => {
      state.categoryFilter = action.payload
      let temp = current(state.products)
      temp = temp.filter((elem) => elem.category === action.payload)
      let temp2 = []
      console.log(temp)
      for (let i = 0; i < temp.length; i++){
        if (!temp2.includes(temp[i].type)){
          temp2.push(temp[i].type)
        }
      }
      console.log(temp2)
      state.types = temp2
    },
    setAuth: (state, action) => {
      state.auth = action.payload
    },
    setVk: (state, action) => {
      state.auth = action.payload
    },
    setUser: (state, action) => {
        state.user = action.payload
    },
    setOrders: (state, action) => {
      state.orders = action.payload
    },
    setNewOrders: (state,action) => {
      let tmp = state.orders_data;
      for(let i=0; i< tmp.length; i++){
        if (tmp[i].orderid === action.payload.orderid ){
          tmp[i].type = action.payload.type;
          
        }
      }
      state.orders_data = tmp;
    },
    setProductToView: (state, action) => {
        state.productToView = action.payload
    },

    setProducts: (state, action) =>{
       state.products = action.payload
       state.isLoading = false
    },
    addBusket: (state, action) =>{

      let flag = false;
      for (let i = 0; i < state.busket.length; i++) {
        
        if (state.busket[i].id === action.payload.id){
          flag = true
          state.busket = state.busket.filter((event) => action.payload.id !== event.id);
        } 
      }
      if (!flag){
        state.busket.push({id: action.payload.id, size: action.payload.size, count:  action.payload.count, img: action.payload.img});
      }
    }
   ,
   
    addToFavorites: (state, action) => {
      if(state.favorites.includes(action.payload.id)){
        state.favorites = state.favorites.filter((event) => action.payload.id !== event);
      }
      else{
        state.favorites.push(action.payload.id);
      }
    },
    setLoading: (state, action) => {

      state.loading= action.payload
  },
    setFavorites: (state, action) => {

        state.favorites = action.payload
    },
    setBusket: (state, action) => {

      state.busket = [].concat(action.payload)
    },
    setLoading: (state, action) => {

      state.isLoading = action.payload
  },
  toogleSearchInput: (state,action) => {
    state.searchInput = !state.searchInput;
  },
  toogleSearchInputBool: (state,action) => {
    state.searchInput = state.action.payload;
  },
  setOrdersData: (state,action) => {
    state.orders_data = action.payload
  }
}}
)

export const { toogleSearchInput , setActual, setOneTypes, setAllFilters, setType, setOpensFilter, setCategories, setNewOrders,setSeriaFilter,setMark, setAuth, setUser, setScroll, setProductToView, setLoading, addToFavorites, setFavorites, setProducts, addBusket, setBusket, setOrders, setVk, setOrdersData, setColorFilter ,setCategoryFilter,setExFilter,setMemoryFilter,setRazmFilter} = storeSlice.actions
export const allFiltersSelector = {
  getAllFilters : (state) => state.allFilters
}
export const getActualFilters = {
  getAll : (state) => state.actual_filters
}
export const markSelector = {
  getMark : (state) => state.markFilter
}
export const userSelector = {
    getUser: (state) => state.user
    
}
export const typeSelector = {
  getType: (state) => state.type
}
export const typesSelector = {
  getTypes: (state) => state.types
}
export const categoriesSelector = {
  getCategories: (state) => state.categories
}
export const AllordersSelector = {
  getOrders: (state) => state.orders_data
}
export const opensFilterSelector = {
  getOpensFilter: (state) => state.opensFilter
}
export const seriaSelector = {
  getSeria: (state) => state.seriaFilter
}
export const scrollSelector = {
  getScroll: (state) => state.scrollPosition
}
export const authSelector = {
    getAuth: (state) => state.auth
}
export const vkSelector = {
  getVk: (state) => state.vk_auth
}
export const memorySelector = {
  getMemory: (state) => state.memoryFilter
}

export const colorSelector = {
  getColor: (state) => state.colorFilter
}
export const loadingSelector = {
  getLoading: (state) => state.loading
}

export const categorySelector = {
  getCategory: (state) => state.categoryFilter
}
export const exFilterSelector = {
  getExFilter: (state) => state.exFilter
}

export const razmSelector= {
  getRazm: (state) => state.razmFilter
}
export const productSelector = {
    getProductToSelect: (state) => state.productToView
}
export const favoritesSelector = {
  getFavorites: (state) => state.favorites
}
export const ordersSelector = {
  getOrders: (state) => state.orders
}
export const getProductsSelector = {
  getProducts: (state) => state.products
}
export const getBusketSelector = {
  getBusket: (state) => state.busket
}
export const getLoading= {
  getIsLoading: (state) => state.isLoading
}
export const searchSelector = {
  getSearchInput: (state) => state.searchInput
}
export default storeSlice


