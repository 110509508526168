import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {favoritesSelector,addToFavorites, authSelector, userSelector, setScroll,id,getProductsSelector,getBusketSelector,addBusket} from '../../slices/SlicesStore';
import { FaRegHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineShoppingBag } from "react-icons/md";

import './card.css'
import $api from '../../requests/index'
import { CiHeart } from "react-icons/ci";
import { PiBagLight } from "react-icons/pi";
import {useActionData, useParams} from 'react-router-dom';

function Card({name,prevPrice,newPrice,categorie,image_url,id}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function translit(word){
    var converter = {
      'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
      'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
      'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
      'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
      'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
      'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
      'э': 'e',    'ю': 'yu',   'я': 'ya'
    };
   
    word = word.toLowerCase();
    
    var answer = '';
    for (var i = 0; i < word.length; ++i ) {
      if (converter[word[i]] == undefined){
        answer += word[i];
      } else {
        answer += converter[word[i]];
      }
    }
   
    answer = answer.replace(/[^-0-9a-z]/g, '-');
    answer = answer.replace(/[-]+/g, '-');
    answer = answer.replace(/^\-|-$/g, ''); 
    return answer;
  }
  const favorites = useSelector(favoritesSelector.getFavorites);
  const auth = useSelector(authSelector.getAuth)
  const user = useSelector(userSelector.getUser)
  const [hover ,setHover] = React.useState(true);
  const handleFavorites = () => {
    // let favorite = [];
    if (!auth){
        if(!localStorage.getItem('favorite')) {
              localStorage.setItem("favorite", JSON.stringify([id]))
          
        }else{

          let data = JSON.parse(localStorage.getItem("favorite"))
          if (!data.includes(id)){
            data.push(id)
          } else {
            data = data.filter((elem) => elem != id)
          }
          
          localStorage.setItem("favorite", JSON.stringify(data))
        }

        
    } else {


      if (favorites.includes(id)){
        
        $api.post(`/user/delFavorite`, {user: user.userid, id: id})
      } else {
        $api.post(`/user/addFavorite`, {user: user.userid, id: id})
      }
      
    }

    dispatch(addToFavorites({id}))
  }

  //busket 

  function checkIfInJson(obj, arr, tip){
    for (let i= 0; i < arr.length; i++){
      if (arr[i][`${tip}`] == obj){
        return true
      }
    }
    return false

  }

  let products = useSelector(getProductsSelector.getProducts)
  const busket = useSelector(getBusketSelector.getBusket);
  const [basket,setBusket] = React.useState(false);
  const params = useParams();
  let product = (products.filter((e) => translit(e.name).toLowerCase() == params.id))[0];
  const [count, setCount] = React.useState(1)


  const handleBusket = () => {
    console.log(1)
    setBusket(!basket)
    if (!auth){
        if(!localStorage.getItem('busket')) {
              localStorage.setItem("busket", JSON.stringify({id: id, count:count,img: image_url}))
          
        }else{
          let data = [].concat(JSON.parse(localStorage.getItem("busket")))
          if (!checkIfInJson(id, data, 'id')){
            data.push({id: id, count:count,img: image_url})
          } else {
            data = data.filter((elem) => elem.id != id)
          }
          
          localStorage.setItem("busket", JSON.stringify(data))
        }

        
    } else {

      if (!checkIfInJson(id, busket, 'id')){
        $api.post(`/user/addBusket`, {user: user.userid, id: id, count:count,img: image_url})
      } else {
        $api.post('/user/delBusket', {user: user.userid, id: id,img: image_url})
      }
      
    }
    dispatch(addBusket({id: id, count:count,img: image_url}))
  }
  return (
    <article className='test__card'  >
      <div className="img__block__icons">
        <img src={`/${image_url}/1.webp`} style={{cursor: "pointer"}} alt={name} onClick={() => navigate(`/catalog/${translit(name)}`, { state: { countProduct: true} })} />
        <div className="icons__card" style={{display: "flex",flexDirection: "column",gap: "1rem"}}>
          {
            favorites.includes(id) ? 
            <button onClick={handleFavorites}><FaHeart style={{color: "rgb(191, 72, 0)"}} className='favorited hover_favorited'/></button> :
            <button onClick={handleFavorites}><FaRegHeart   className='favorited hover_favorited'/></button>
          }
          <PiBagLight onClick={handleBusket} className='hover_favorited' style={{fontSize: "20px", cursor: "pointer"}}/>
        </div>
      </div>
      <div className="options__card" onClick={() => navigate(`/catalog/${translit(name)}`)} style={{cursor: "pointer"}}>
        <h3>{name}</h3>
        <div className="prise_card-col">
          <div className="col-1">
              <p>{newPrice} ₽</p>
          </div>
          <div className="col-2">
            <IoIosArrowForward onClick={() => navigate(`/catalog/${translit(name)}`)} style={{fontSize: "20px"}}/>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Card
