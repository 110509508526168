import React, { useEffect } from 'react'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import '../styles/card-page.scss'
import { CiHeart } from "react-icons/ci";
import $api from "../components/requests/index"
import {useSelector, useDispatch} from 'react-redux'
import {addBusket,getProductsSelector, setType, setAllFilters, setOpensFilter, setCategoryFilter, getLoading, getBusketSelector, userSelector, authSelector,addToFavorites,favoritesSelector} from '../components/slices/SlicesStore';
import {useActionData, useParams} from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import ErrorPage from './ErrorPage';
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import MySlider from '../components/slider/MySlider';
import { Link } from 'react-router-dom';
import strelka from '../images/icons/strelka.svg'
import BannerPage from '../components/header/BannerPage';
import SliderCardPage from '../components/slider/SliderCardPage';
import SliderImages from './SliderImages';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Swiper from '../components/slider/Swiper'
import SwiperCard from '../components/slider/SwiperCard';
import Fancybox from '../components/slider/FancyBox';
import Helmet from 'react-helmet'
function CardPage() {

  function checkIfInJson(obj, arr, tip){
    for (let i= 0; i < arr.length; i++){
      if (arr[i][`${tip}`] == obj){
        return true
      }
    }
    return false

  }
  function translit(word){
    var converter = {
      'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
      'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
      'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
      'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
      'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
      'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
      'э': 'e',    'ю': 'yu',   'я': 'ya'
    };
   
    word = word.toLowerCase();
    
    var answer = '';
    for (var i = 0; i < word.length; ++i ) {
      if (converter[word[i]] == undefined){
        answer += word[i];
      } else {
        answer += converter[word[i]];
      }
    }
   
    answer = answer.replace(/[^-0-9a-z]/g, '-');
    answer = answer.replace(/[-]+/g, '-');
    answer = answer.replace(/^\-|-$/g, ''); 
    return answer;
  }
  const params = useParams();
  const dispatch = useDispatch()
  let products = useSelector(getProductsSelector.getProducts)
  const busket = useSelector(getBusketSelector.getBusket);
  const isLoading = useSelector(getLoading.getIsLoading)
  const user = useSelector(userSelector.getUser)
  const auth = useSelector(authSelector.getAuth)
  const [size, setSize] = React.useState(null)
  const [toCount, setToCount] = React.useState(false)
  const [count, setCount] = React.useState(1)
  const [stock, setStock] = React.useState(0)
  const [basket,setBusket] = React.useState(false);
  const {state} = useLocation();

  let product = (products.filter((e) => translit(e.name).toLowerCase() == params.id))[0]
  
  useEffect(() => {
      if (product){
          setStock(product.stock)
      }
  }, [product]);
  const handleSize = (e) => {
    setCount(1)
    setSize(e)
    product.sizes.map((elem) => {
      if (elem.size === e){
        setStock(elem.stock)
      }
    })

  }
  const handleIncrement = () => {
    if (count + 1 <= stock){
      setCount(count+1)
      setToCount(true)
    }
    
  }
  const handleDecrement = () => {
    if (count > 1){
      setCount(count-1)
      setToCount(true)
    }
    
  }
  const handleBusket = () => {
    console.log(1)
    setBusket(!basket)
    if (!auth){
        if(!localStorage.getItem('busket')) {
              localStorage.setItem("busket", JSON.stringify({id: product.id, size: size, count:count}))
          
        }else{
          let data = [].concat(JSON.parse(localStorage.getItem("busket")))
          if (!checkIfInJson(product.id, data, 'id')){
            data.push({id: product.id, size: size, count:count})
          } else {
            data = data.filter((elem) => elem.id != product.id)
          }
          
          localStorage.setItem("busket", JSON.stringify(data))
        }

        
    } else {

      if (!checkIfInJson(product.id, busket, 'id')){
        $api.post(`/user/addBusket`, {user: user.userid, id: product.id, size: size, count:count})
      } else {
        $api.post('/user/delBusket', {user: user.userid, id: product.id})
      }
      
    }
    dispatch(addBusket({id: product.id, size: size, count:count}))
  }
  const [images, setImages] = React.useState([])
  const [similar, setSimilar] = React.useState([])
  const [discriptionOpen,setDiscriptionOpen] = React.useState(false);
  const discriptionHandler = () => {
    setDiscriptionOpen(!discriptionOpen);
    setArrow(!arrow);
  }
  const navigate = useNavigate()
  const [arrow,setArrow] = React.useState(false);
  const favorites = useSelector(favoritesSelector.getFavorites);
  const handleFavorites = () => {
    // let favorite = [];
    if (!auth){
        if(!localStorage.getItem('favorite')) {
              localStorage.setItem("favorite", JSON.stringify([product.id]))
          
        }else{

          let data = JSON.parse(localStorage.getItem("favorite"))
          if (!data.includes(product.id)){
            data.push(product.id)
          } else {
            data = data.filter((elem) => elem != product.id)
          }
          
          localStorage.setItem("favorite", JSON.stringify(data))
        }

        
    } else {


      if (favorites.includes(product.id)){
        
        $api.post(`/user/delFavorite`, {user: user.userid, id: product.id})
      } else {
        $api.post(`/user/addFavorite`, {user: user.userid, id: product.id})
      }
      
    }

    dispatch(addToFavorites({id:product.id}))
  }
  	useEffect(() => {
      if (state){
        if (state.countProduct){
          setSize(null)
          setCount(1)
          if (product){
              setStock(product.stock)

          }
          setBusket(false)
        }
      }


  	}, [state, product])
    useEffect(() => {
      if (product && !isLoading ){
        if (checkIfInJson(product.id, busket, 'id')){
    			setBusket(true)
  		  }
          let temp = []
          for (let i = 0; i < product.imgCount; i++) {
            console.log(images)
            if (product.imgCount === 1){
              temp.push(<img key={`${product.name}_${i}`} className="img-card-page-one" loading="lazy" src={`/${product.imageUrl}/${i+1}.webp`} alt={product.name}></img>);
              break
            } else {
              temp.push(<img key={`${product.name}_${i}`} className="img-card-page" loading="lazy" src={`/${product.imageUrl}/${i+1}.webp`} alt={product.name}></img>);
            }
            
        }
        setImages(temp)
        temp = products.filter((e) => e.category == product.category && e.id !== product.id)
        temp.slice(20)
        setSimilar(temp)
      }


  	}, [product])
  return (
    <>
    {!isLoading && product ? 
        <>
                <div className='card-page-container'>
                <Helmet>
                  <title>{`Agent KEI | ${product.name}`}</title>
                  <meta name="twitter:title" content={`Agent KEI | ${product.name}`}/>
                  <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
                  <meta property="og:title" content={`Agent KEI | ${product.name}`}/>
                  <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
                  <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
                </Helmet>
                  <div className="card-page-content">
                    <div className="first-block-card-page">
                      <div className="block-image-card-page">
                      <div className='container-prodyct-map'>
                     

                          <p className='map-link' onClick={() => {navigate('/catalog');dispatch(setType(null));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter(null))}}>Каталог</p>
                          <p> / </p>
                          <p className='map-link' onClick={() => {navigate('/catalog');dispatch(setType(null));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter(product.category))}}>{product.category}</p>
                          {product.type != "" ? 
                          <>
                          <p> / </p>
                          
                          <p className='map-link' onClick={() => {navigate('/catalog');dispatch(setType(product.type));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter(product.category))}}>{product.type}</p>
                          </>
                          :
                          null
                          }
                          <p> / </p>
                          <p className='map-link'>{product.name}</p>
      

                      </div>
                        <SliderImages data={images}/>
                      </div>
                      <div className="discription-card-page">
                        <div className="name-card-page">
                          <h2>{product.name}</h2>
                          {/* <CiHeart className='heart-card-page' /> */}
                          {
                            favorites.includes(product.id) ? 
                            <button onClick={handleFavorites}><FaHeart style={{color: "rgb(191, 72, 0)"}} className='favorited'/></button> :
                            <button onClick={handleFavorites}><FaRegHeart style={{color: "rgb(191, 72, 0)"}} className='favorited'/></button>
                          }
                        </div>
                        <div className="price-card-page">
                        {product.prevPrice === product.newPrice || Number(product.prevPrice) === 0 ? <p key={`${product.newPrice}`} className='price'>{product.newPrice} ₽</p> : <><p className='price'>{product.newPrice} ₽</p><p key={`${product.prevPrice}`}className='prev-price'>{product.prevPrice} ₽</p></>}
                        </div>

                        {
                          !basket ? <button onClick={handleBusket} className="add-card-page">Добавить в корзину</button> : <button onClick={handleBusket} className="add-card-page active">Товар добавлен в корзину</button>
                        }
                        {/* <button onClick={handleBusket} className="add-card-page">Добавить в корзину</button> */}
                         <Link to='/prodpage'><button onClick={handleBusket} className="oformlenie-card-page">Оформить заказ</button> </Link> 
                        
                          {/* <button onClick={handleBusket} className="oformlenie-card-page">Оформить заказ</button> */}

                        <div className='discpr-card-page'>
                          <div onClick={discriptionHandler}>
                            <h2>Описание</h2>
                            {
                              arrow === true ? <MdOutlineKeyboardArrowDown className='arrow-discription' /> : <MdOutlineKeyboardArrowUp className='arrow-discription' />
                            }
                            
                          </div>
                          {
                            discriptionOpen ? <p>{product.description}</p> : ""
                          }
                          
                        </div>
                      </div>
                        
                    </div>
                      <div className="second-block-card-page">
                            <h1>Похожие товары</h1>
                            <Swiper key='mobile' type='mobile' data={similar}/>
                        </div>
                  </div>
                </div>
        </>
  : !product && isLoading  ? <p>LOADING</p>:
    <ErrorPage/>
    }

    </>
  )
}

export default CardPage;
