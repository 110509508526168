import React, { useEffect } from 'react'
import './header.scss';

// import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import searchIcons from '../../images/icons/search-icons.svg'
import heartIcons from '../../images/icons/heart-icons.svg'
import userIcons from '../../images/icons/user-icons.svg'
import bagIcons from '../../images/icons/bag-icons.svg'
import bagIconsTwo from '../../images/icons/bag-icons-2.svg'
import {useSelector, useDispatch} from 'react-redux'
import {FaBars,FaTimes} from 'react-icons/fa';
import { userSelector, getProductsSelector, favoritesSelector,  setProductToView, setAuth, setUser, setType, setCategories, setOpensFilter,setAllFilters, authSelector,setCategoryFilter, categoriesSelector } from '../slices/SlicesStore';
import $api from '../requests/index'
import SearchInput from './SearchInput';
import {toogleSearchInput,searchSelector} from '../slices/SlicesStore'
import logoTablet from '../../images/icons/logo-tablet.svg'
import { IoSearch } from "react-icons/io5";
import menuBtn from '../../images/icons/btn-tablet-menu.svg'
import closeBtn from '../../images/icons/close-icons-white.svg'
import { FaPhoneAlt } from "react-icons/fa";
// import {useSelector, useDispatch} from 'react-redux';
import {getBusket,getBusketSelector} from '../slices/SlicesStore'
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { FiUserCheck } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa";
// import { IoSearch } from "react-icons/io5";
import { MdOutlineShoppingBag } from "react-icons/md";
import logotype from '../../images/apple_icon/logo.svg'
import { IoCloseOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";

import { CiHeart } from "react-icons/ci";
import { PiBagLight } from "react-icons/pi";
import { FaUserAlt } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { SlSocialVkontakte } from "react-icons/sl";
import Card from '../content/products/Card';
import { FaVk } from "react-icons/fa";

// import { RxHamburgerMenu } from "react-icons/rx";
function Header() {
    const user = useSelector(userSelector.getUser)
    const isAuth = useSelector(authSelector.getAuth)
    const products = useSelector(getProductsSelector.getProducts)
    const categories = useSelector(categoriesSelector.getCategories)
    const favorites = useSelector(favoritesSelector.getFavorites)
    const [openSearch, setSearch] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("")
    const [searchResult, setResult] = React.useState([])
    useEffect(() => {
         let temp = []
        for (let i = 0; i < products.length; i++){
            if (!temp.includes(products[i].category)){
                temp.push(products[i].category)
            }
        }
        dispatch(setCategories(temp))
    }, [products])
    


    

    
    // const [isMobileMeneOpen,setIsMobileMeneOpen] = React.useState(false);
    // const handleClickMobileMenu = () => {
    //     setIsMobileMeneOpen(!isMobileMeneOpen);
    // }


    const [isBasketOpen,setBasketOpen] = React.useState(false);
    const handleClickBasket = () => {
        setBasketOpen(!isBasketOpen);
    }

    // const [searchInput,setSearchInput] = React.useState(false);
    // const handleToogleInput = () => {
    //  setSearchInput(!searchInput);
    //  console.log('open search');
    // }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const search = useSelector(searchSelector.getSearchInput);

    const busket = useSelector(getBusketSelector.getBusket);

    const[isOpenIconsSearch, setIsOpenIconsSearch] = React.useState(true);


    const [isMobileMeneOpen,setIsMobileMeneOpen] = React.useState(false);
    const handleClickMobileMenu = () => {
        setIsMobileMeneOpen(!isMobileMeneOpen);
        setIsOpenIconsSearch(!isOpenIconsSearch);

    }
    const handleClickMobileMenu2 = (e) => {
        setIsMobileMeneOpen(e);

    }
    const [scrolled, setScrolled] = React.useState(false);

    useScrollPosition(
        ({ prevPos, currPos }) => {
        const isScrolled = currPos.y < -50;
        if (isScrolled !== scrolled) {
            setScrolled(isScrolled);
        }
        },
        [scrolled]
    );

    useEffect(() => {
        if (searchValue == ""){
            setResult([])
        } else {
            let temp = [];
            for (let i = 0; i < products.length; i++){
                if (products[i].name.toLowerCase().includes(searchValue.toLowerCase())){
                    temp.push(products[i])
    
                }
            }
            setResult(temp)
        }

    }, [searchValue])
    const location = useLocation();
    
    useEffect(() => {
      setSearch(false)
      setResult([])
    }, [location]);
    useEffect(() =>{
        if (openSearch){
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [openSearch])
   return (
    <header>
        <div className="header__black__block">
            <ul className='discription__header'>
                <li><Link onClick={() => handleClickMobileMenu2(false)} to='/about' state={{ param: "1" }}>Информация</Link></li>
                <li><Link onClick={() => handleClickMobileMenu2(false)} to='/about' state={{ param: "4" }}>Ремонт</Link></li>
                <li><Link onClick={() => handleClickMobileMenu2(false)} to='/about' state={{ param: "5" }}>Контакты</Link></li>
            </ul>
            <ul className='contacts__header'>
                <li className='phoner-text-header'> <a href="tel:+79507541317"  style={{fontSize: "14px", color:"#fff"}}>+7 (950) 754-13-17</a></li>
                <li className='phoner-icon-header'> <a href="tel:+79507541317"><FaPhoneAlt style={{fontSize: "16px", color:"#fff"}} /></a></li>
                <li> <a href="https://t.me/wovvont"><FaTelegram style={{fontSize: "16px", color:"#fff"}} /></a></li>
                <li><a href="https://vk.com/agent_kei36"><FaVk style={{fontSize: "16px", color:"#fff"}} /></a></li>
                
            </ul>
        </div>

        <div className="header__white__block">
            <div className="first__section__header">
                <Link onClick={() => handleClickMobileMenu2(false)} to="/"><img src={logotype} alt="" /></Link>
                <ul>
                    {categories.map((elem) => <li onClick={() => {dispatch(setProductToView(12));navigate('/catalog');dispatch(setType(null));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter(elem))}}>{elem}</li>)}
                   
                </ul>
            </div>
            
            <div className="second__section__header">
                {openSearch || isMobileMeneOpen ? null : 
                <div className='search_button'>
                    <button onClick={() => setSearch(!openSearch)}><IoIosSearch style={{fontSize: "20px"}} /></button>
                </div>
                }

                <div className="icon-line-header">
                <Link onClick={() => handleClickMobileMenu2(false)} to='/favorited'><CiHeart style={{fontSize: "20px"}}/><p className='namber_favorited'>{favorites.length != 0 ? favorites.length : null}</p></Link> 
                </div>
                <div className="icon-line-header icon-line-header-fix">
                <Link onClick={() => handleClickMobileMenu2(false)} to="/prodpage"><PiBagLight style={{fontSize: "20px"}}/><p className='namber_favorited'>{busket.length != 0 ? busket.length : null}</p></Link>
                </div>
                <div className="info__user__header">
                    {/* Добавить ссылку на акк */}
                    {
                        isAuth ? 
                        <>
                            <Link  to="/userpage"><p>{user.phone}</p></Link>
                            <Link to="/userpage"><strong>{user.name}</strong></Link>
                        </> : 
                        <Link to="/userpage" className='autori'><strong>Авторизация</strong></Link>
                    }      

                </div>


                {/* burger */}
                <div className='burger__menu burger'>
                {
                            isMobileMeneOpen === false ? 
                           <RxHamburgerMenu onClick={handleClickMobileMenu} style={{fontSize: "26px"}}/>  :
                            <IoCloseOutline onClick={handleClickMobileMenu} style={{fontSize: "26px"}} />
                        }
                    {
                isMobileMeneOpen ? 
                <div className="header-link-column">
                     <ul>
                    {categories.map((elem) => <li onClick={() => {navigate('/catalog');handleClickMobileMenu();dispatch(setType(null));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter(elem))}}>{elem}</li>)}
                    <li>Ремонт</li>
                </ul>

                <div onClick={() => {navigate('/userpage');handleClickMobileMenu()}} className="uzer-cabinet-mobile">
                   
                                <div className=" user-burger-col">
                                    <div className="">
                                        <FaUserAlt style={{fontSize: "20px", color: "#2e2e2e"}} />
                                    </div>
                                    <div className="">
                                        <p className='link-profile'>Личный кабинет</p>
                                         {
                                            isAuth ? 
                                            <>
                                                <strong>{user.name}</strong>
                                            </> : 
                                            <strong>Авторизация</strong>
                                        }  
                                    </div>
                                </div>
                   
                </div>

                </div> : ''
            }
                </div>
                
 


            </div>


        </div>
        {openSearch ? 
            <>
            <div onClick={() => {setSearch(!openSearch);setResult([])}} className='search'>

            </div>
            
            <div className="search_block">
                <div className="container-input-search grid">
                   <div className="container-contain-search grid justi">
                         <div className="contaner-search">
                            <input onChange={(e) => setSearchValue(e.target.value)} placeholder='Что вы ищите'></input>
                        </div>
                        <div className="contaner-clease">
                            <button onClick={() => {setSearch(!openSearch);setResult([])}}><IoCloseOutline  style={{fontSize: "26px"}} /></button>
                        </div>
                   </div>
                    
                    
                </div>
                
                {searchResult.length == 0 ?
                <>
                <div className='search_result_name grid'>
                    <p >Ничего не написали</p>
                </div>
                </>
                :
                
                <>
                <div className='resultat-poiska'>
                     <p>Результаты поиска</p>
                </div>
                <div className='search_result'>

                {searchResult.slice(0,6).map((obj) =>
                <div onClick={() => {setSearch(!openSearch);setResult([])}}>
                  <Card 
                    id={obj.id}
                    key={obj.id}
                    name={obj.name}
                    newPrice={obj.newPrice}
                    prevPrice={obj.prevPrice}
                    // size={obj.size}
                    // sizes = {obj.sizes}
                    image_url={obj.imageUrl}
                    imgCount={obj.imgCount}
                  />
                  </div>)}
                  </div>
                </>
                
                }
                
            </div>
            </>
            :
                null
            }

    </header>
  )
}

export default Header;
