import React from 'react'
import '../styles/cardForUserCab.css'
import {useSelector, useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom";
import { getProductsSelector, productSelector, setProductToView, getLoading, getBusketSelector, userSelector, authSelector, addBusket } from '../components/slices/SlicesStore';
import $api from "../components/requests/index"
import { FaRegTrashCan } from "react-icons/fa6";
function CardDoOrder({imgUrl,name,newPrice,size, count, id}) {
  const navigate = useNavigate();
  function translit(word){
    var converter = {
      'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
      'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
      'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
      'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
      'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
      'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
      'э': 'e',    'ю': 'yu',   'я': 'ya'
    };
   
    word = word.toLowerCase();
    
    var answer = '';
    for (var i = 0; i < word.length; ++i ) {
      if (converter[word[i]] == undefined){
        answer += word[i];
      } else {
        answer += converter[word[i]];
      }
    }
   
    answer = answer.replace(/[^-0-9a-z]/g, '-');
    answer = answer.replace(/[-]+/g, '-');
    answer = answer.replace(/^\-|-$/g, ''); 
    return answer;
  }
    function checkIfInJson(obj, arr, tip){
        for (let i= 0; i < arr.length; i++){
          if (arr[i][`${tip}`] == obj){
            return true
          }
        }
        return false
    
      }

    const dispatch = useDispatch()
    const busket = useSelector(getBusketSelector.getBusket);
    const user = useSelector(userSelector.getUser)
    const auth = useSelector(authSelector.getAuth)
    const handleDelBusket = () => {
        // let favorite = [];
        if (!auth){
            if(!localStorage.getItem('busket')) {
                  localStorage.setItem("busket", JSON.stringify({id: id, size: size, count:count}))
              
            }else{
              let data = [].concat(JSON.parse(localStorage.getItem("busket")))
              if (!checkIfInJson(id, data, 'id')){
                data.push({id: id, size: size, count:count})
              } else {
                data = data.filter((elem) => elem.id != id)
              }
              
              localStorage.setItem("busket", JSON.stringify(data))
            }
        } else {
    
          if (!checkIfInJson(id, busket, 'id')){
            $api.post(`/user/addBusket`, {user: user.userid, id: id, size: size, count:count})
          } else {
            $api.post('/user/delBusket', {user: user.userid, id: id})
          }
          
        }
        dispatch(addBusket({id: id, size: size, count:count}))
      }
  return (
    <>
        <div className='card-container-zakaz'>
            <div className="discription-zakaz-container">
                <img className='img-zakaz' src={`${imgUrl}`} alt={name} />
                <div className="discription-zakaz">
                    <p onClick={() => navigate(`/catalog/${translit(name)}`)} className='name-zakaz'>{name}</p>
                    {/* <div className="articul-container">
                        <p className='articul'>Артикул:</p>
                        <p className='articul-number'>{newPrice}</p>
                    </div> */}
                    {/* <div className="size-container">
                        {size && !(size === "null")? 
                        <>
                        <p className='size-zakaz'>Размер:</p>
                        <p className='size-zakaz-info'> {size}</p>
                        </>
                        : null}

                    </div> */}
                </div>
            </div>
            <div className="price-zakaz-container">
                <p className='count-zakaz'>{count} шт.</p>
                <p className='price-zakaz'>{newPrice} ₽</p>
                <FaRegTrashCan className='trash' onClick={handleDelBusket} />
            </div>
        </div>

        <div className='card-container-zakaz-tablet'>
        <div className="discription-zakaz-container">
                <img src={`${imgUrl}`} alt={name} />
                <div className="discription-zakaz">
                    <p onClick={() => navigate(`/catalog/${translit(name)}`)} className='name-zakaz'>{name}</p>
                    {/* <div className="articul-container">
                        <p className='articul'>Артикул:</p>
                        <p className='articul-number'>{newPrice}</p>
                    </div> */}
                    {/* <div className="size-container">

                        <p className='size-zakaz'>Размер: </p>
                        <p className='size-zakaz-info'> {size}</p>

                    </div> */}
                    <div className="count-zakaz-container">
                      <div className="count-container-mobile">
                        <p className='count-zakaz'>Кол-во:</p>
                        <p className='count-number'>{count}шт.</p>
                      </div>
                      
                      <div className='asd'>
                        <div className='price-zakaz-container'>
                          <p className='price-zakaz'>Цена:</p>
                          <p className='price-number'>{newPrice} ₽</p>
                        </div>
                        

                        <FaRegTrashCan className='trash' onClick={handleDelBusket} />
                      </div>
                    </div>
                </div>
                
            </div>
        </div>

        <div className='card-container-zakaz-mobile'>
        <div className="discription-zakaz-container">
                <img src={`${imgUrl}`} alt={name} />
                <div className="discription-zakaz">
                    <p onClick={() => navigate(`/catalog/${translit(name)}`)} className='name-zakaz'>{name}</p>
                    {/* <div className="articul-container">
                        <p className='articul'>Артикул:</p>
                        <p className='articul-number'>{newPrice}</p>
                    </div> */}
                    {/* <div className="size-container"> */}
                        {/* {size && !(size === "null")? 
                        <>

                        </>
                        : null} */}
                        {/* <p className='size-zakaz'>Размер: </p>
                        <p className='size-zakaz-info'> {size}</p> */}

                    {/* </div> */}
                    <div className="count-zakaz-container">
                      <div className="count-container-mobile">
                        <p className='count-zakaz'>Кол-во:</p>
                        <p className='count-number'>{count}шт.</p>
                      </div>
                      
                      <div className='asd'>
                        <div className='price-zakaz-container'>
                          <p className='price-zakaz'>Цена:</p>
                          <p className='price-number'>{newPrice} ₽</p>
                        </div>
                        

                        <FaRegTrashCan className='trash' onClick={handleDelBusket} />
                      </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    </>
  )
}

export default CardDoOrder
