import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {favoritesSelector,addToFavorites, authSelector, userSelector} from '../components/slices/SlicesStore';
import { FaRegHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import '../styles/card-big.scss'
import $api from '../components/requests/index'
import { PiBagLight } from "react-icons/pi";


function CardBig({ name,newPrice,prevPrice,size,image_url,id, sizes, imgCount}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function translit(word){
    var converter = {
      'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
      'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
      'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
      'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
      'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
      'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
      'э': 'e',    'ю': 'yu',   'я': 'ya'
    };
   
    word = word.toLowerCase();
    
    var answer = '';
    for (var i = 0; i < word.length; ++i ) {
      if (converter[word[i]] == undefined){
        answer += word[i];
      } else {
        answer += converter[word[i]];
      }
    }
   
    answer = answer.replace(/[^-0-9a-z]/g, '-');
    answer = answer.replace(/[-]+/g, '-');
    answer = answer.replace(/^\-|-$/g, ''); 
    return answer;
  }
  const favorites = useSelector(favoritesSelector.getFavorites);
  const auth = useSelector(authSelector.getAuth)
  const user = useSelector(userSelector.getUser)
  const [hover ,setHover] = React.useState(true);
  const handleFavorites = () => {
    // let favorite = [];
    if (!auth){
        if(!localStorage.getItem('favorite')) {
              localStorage.setItem("favorite", JSON.stringify([id]))
          
        }else{

          let data = JSON.parse(localStorage.getItem("favorite"))
          if (!data.includes(id)){
            data.push(id)
          } else {
            data = data.filter((elem) => elem != id)
          }
          
          localStorage.setItem("favorite", JSON.stringify(data))
        }

        
    } else {


      if (favorites.includes(id)){
        
        $api.post(`/user/delFavorite`, {user: user.userid, id: id})
      } else {
        $api.post(`/user/addFavorite`, {user: user.userid, id: id})
      }
      
    }

    dispatch(addToFavorites({id}))
  }



  
  return (
    <article className='test__card' >
      <div className="img__block__icons">
        <img src={`${image_url}/1.webp`} alt={name} onClick={() => navigate(`/catalog/${translit(name)}`, { state: { countProduct: true} })} />
        <div className="icons__card" style={{display: "flex",flexDirection: "column",gap: "1rem"}}>
          {
            favorites.includes(id) ? 
            <button onClick={handleFavorites}><FaHeart className='favorited'/></button> :
            <button onClick={handleFavorites}><FaRegHeart className='favorited'/></button>
          }
          <PiBagLight style={{fontSize: "20px"}}/>
        </div>
      </div>
      <div className="options__card">
        <h3>{name}</h3>
        <p>{newPrice} ₽</p>
      </div>
    </article>
    
  )
}

export default CardBig
