import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import '../styles/policy.scss'
import { Link } from 'react-router-dom'
import { FaTelegram } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoWhatsapp } from "react-icons/io";
import { SlSocialVkontakte } from "react-icons/sl";
import { useLocation,useNavigate } from 'react-router-dom'
import yt from '../images/icons/youtube.svg'
import inst from '../images/icons/instagram.svg'
import tg from '../images/icons/telegram.svg'
import vk from '../images/icons/vk.svg'
import { FaVk } from "react-icons/fa";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Helmet from 'react-helmet';
function Policy() {


    const location = useLocation()
    const navigate = useNavigate()
  return (
    <div >
        <Helmet>
          <title>{`Agent KEI | Политика конфиденциальности`}</title>
          <meta name="twitter:title" content={`Agent KEI | Политика конфиденциальности`}/>
          <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
          <meta property="og:title" content={`Agent KEI | Политика конфиденциальности`}/>
          <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
          <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
        </Helmet>
      <Header/>
    <div className="container_info">
    <div className="containerAbout">
                <div className="col-1">
                <div className="info_heade_page ">
                    <h1>Политика конфиденциальности</h1>
                    </div>

                </div>

        </div>
        <div className="contaiber-info-page-info">

                        <div className="link1 link_contend">
                            <h2>Политика обработки персональных данных</h2>
                                <div className="">
                                    <p>
                                    <br/>I. Общие положения 
               <br/>1. Обработка персональных данных выполняется с использованием средств автоматизации или без использования таких средств и включает сбор; запись; систематизацию; накопление; хранение; использование; удаление; уничтожение персональных данных субъектов персональных данных, персональные данные которых обрабатываются ИП Косыгин Егор Иванович . (Далее “Agent KEI ”). 
               <br/><br/>2. К субъектам персональных данных, персональные данные которых обрабатываются Agent KEI  относятся: 
               <br/>1) посетители сайта; 
               <br/>2) клиенты/потенциальные; 
               <br/>3) представители контрагентов. 
               <br/><br/>3. Обработка персональных данных лиц, указанных в пункте 2 настоящей Политики, осуществляется с согласия субъекта персональных данных на основании  пункта 1 части 1 статьи 6 Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных»(далее – Федеральный закон «О персональных данных»), без согласия субъекта в случае, если обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем. Заключаемый с субъектом персональных данных договор не может содержать положения, ограничивающие права и свободы субъекта персональных данных на основании п. 5 ч. 1 ст. 6 Федерального закона «О персональных данных» 
               <br/><br/>4. Обработка персональных данных Agent KEI осуществляется с соблюдением принципов и условий, предусмотренных настоящими правилами и законодательством Российской Федерации в области персональных данных. 
               <br/>
               <br/>
               <br/>II. Цели, условия и порядок обработки персональных данных и соответствующие им перечни обрабатываемых персональных данных 
               <br/>
               <br/><br/>5. В целях оформления заказа через сайт, получения почтовой рассылки или новостей, а также регистрации на сайте обрабатываются следующие персональные данные посетителей сайта, потенциальных клиентов: 
               <br/>1) фамилия, имя, отчество; 
               <br/>2) адрес электронной почты; 
               <br/>3) номер телефона. 
               <br/>
               <br/>6. В целях продажи и ремонта мобильных телефонов, компьютеров, фото-видео аппаратуры, бытовой техники обрабатываются следующие персональные данные клиентов, представителей контрагентов: 
               <br/>1) фамилия, имя, отчество; 
               <br/>2) адрес электронной почты; 
               <br/>3) номер телефона. 
               <br/>4) адрес места жительства; 
               <br/>5) ИНН; 
               <br/>6)  данные документа, удостоверяющего личность;  
               <br/>7) номер расчетного счета; 
               <br/><br/>7. В целях использования на сайте Agent KEI  метрических программ Яндекс Метрика, Google Analytics обрабатываются следующие персональные данные посетителей сайта: геолокация, возраст, тип устройства (пк, планшет, смартфон), ip адрес. 

               <br/><br/>
               <br/>III. Сроки обработки и хранения персональных данных.  
               <br/>Порядок уничтожения персональных данных 

               <br/><br/>8. Персональные данные обрабатываются до отписки физического лица от всех рекламных и новостных рассылок.  
               <br/><br/>9. Обработка персональных данных также может быть прекращена по запросу субъекта персональных данных.  
               <br/><br/>10. Хранение персональных данных на бумажных носителях осуществляется согласно Федеральному закону №125-ФЗ «Об архивном деле в Российской Федерации» и иным нормативно правовым актам в области архивного дела и архивного хранения. 
               <br/><br/>11. Персональные данные подлежат уничтожению в следующих случаях: 
               <br/>1) При достижении цели обработки персональных данных или в случае утраты необходимости в достижении цели обработки персональных данных, если иное не предусмотрено Федеральным законом «О персональных данных»; 
               <br/>2) При изменении, признании утратившими силу нормативных правовых актов, устанавливающих правовые основания обработки персональных данных; 
               <br/>3) При выявлении факта неправомерной обработки персональных данных; 
               <br/>4) При отзыве субъектом персональных данных согласия, если иное не предусмотрено Федеральным законом «О персональных данных». 
               <br/><br/>12. Уничтожение персональных данных — это действия, по итогам которых данные нельзя восстановить, в том числе уничтожение носителей (п. 8 ст. 3 закона от 27.07.2006 № 152-ФЗ).
                                    </p>
                                </div>
                        </div>   
                        

        </div>

    </div>
      {/* <Footer/> */}
    </div>
  )
}

export default Policy
