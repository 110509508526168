import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux'
import {scrollSelector, getLoading, loadingSelector,setScroll} from './slices/SlicesStore';
import { IoEllipseSharp } from 'react-icons/io5';
const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  const dispatch= useDispatch()
  const pageScrollPosition = useSelector(scrollSelector.getScroll);
  const loading = useSelector(loadingSelector.getLoading);
  useEffect(() => {
    if (pathname == "/catalog"){
          setTimeout(()=>window.scrollTo({top: pageScrollPosition,  behavior: 'smooth'}),  100)
          dispatch(setScroll(0))
         

    } else {
      console.log(1)
      window.scrollTo({top:0})
      
    }
    
  }, [pathname,loading]);
}

export default ScrollToTop;