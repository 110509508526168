import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import './favorited.scss'
import {useSelector, useDispatch} from 'react-redux'
import $api from '../../components/requests/index'
import {favoritesSelector,addToFavorites, authSelector, userSelector, getProductsSelector, getLoading} from '../../components/slices/SlicesStore';
import Card from '../CardBig'
import BannerPage from '../../components/header/BannerPage'
import CardBig from '../CardBig'
import Helmet from 'react-helmet'
function Favorited() {

  const auth = useSelector(authSelector.getAuth)
  const isLoading = useSelector(getLoading.getIsLoading)
  const favorites = useSelector(favoritesSelector.getFavorites);
  const products = useSelector(getProductsSelector.getProducts)
  const user = useSelector(userSelector.getUser)
  let data = []
  if (isLoading == false){
    products.map((elem) => {
      if (favorites.includes(elem.id)){
        data.push(elem)
      }
    })
  } 
  
      if (isLoading == true){
        return (
          <div className='catalog-container'>
          <Header/>
          <div className="catalog">LOADING...</div>
          <Footer/>
          </div>
          
        )
      } else if (isLoading == false){

        return (
          <div className='favorited-container'>
          <Helmet>
          <title>Agent KEI | Избранное</title>
          <meta name="twitter:title" content="Agent KEI | Избранное"/>
          <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
          <meta property="og:title" content="Agent KEI | Избранное"/>
          <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
          <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
        </Helmet>
          <div className="info_heade">
              <h1>Избранное</h1>
          </div>
            <section id="favorited__block">
                <div className="fav-content">
                      {data.length !== 0 ? 
                        data.map((obj) => {
                          return (
                            <CardBig 
                              id={obj.id}
                              key={obj.id}
                              name={obj.name}
                              newPrice={obj.newPrice}
                              prevPrice={obj.prevPrice}
                              // size={obj.size}
                              // sizes = {obj.sizes}
                              image_url={obj.imageUrl}
                              imgCount={obj.imgCount}
                              />
                        )})
                     
                      : null}
                      
                </div>
                {data.length === 0 ? <p className='nothing__favorited'>Еще ничего не добавлено...</p> : null}
            </section>
          </div>
        )
      } else {
        return (
          <div className='catalog-container'>
          <Header/>
          <div className="catalog"></div>
          <Footer/>
          </div>
        )
      }
  }


export default Favorited