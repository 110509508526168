
import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import '../styles/reset-pass.css'
import $api from '../components/requests/index'
import { useNavigate, useLocation} from "react-router-dom";
import {useDispatch} from 'react-redux'
import {setAuth, setUser, setFavorites, setProducts, setBusket, setOrders} from '../components/slices/SlicesStore';
import Forms from "./Forms"
import Helmet from 'react-helmet'


function ResetPass() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [incorrect, setIncor] = React.useState('');
    const [correct,setCorrect] = React.useState(null);
    const [incorrect2, setIncor2] = React.useState('');
    const [toChange, setToChange] = React.useState('');
    const [toPutCode, setToPut] = React.useState('');
    const [codeInput, setCodeInput] = React.useState('');
    const [password1, setPassword1] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [email, setPochta] = React.useState(null);
    const [code, setCode] = React.useState('');

    const handleReset = () =>{
        $api.post('/reset_password', {email: email})
        .then((e) => {
            setCode(e.data.code)
            setToPut(true)
            setIncor('')
        })
        .catch(function (error) {
            setIncor(error.response.data.message)
          
        
        })
    }
    const handleCodeCheck = (e) =>{
        if (codeInput === code){
            setToChange(true)
        } else {
            setIncor2("Неверный код")
        }
    }
    const handleCheck = (e) =>{
        if (password1 === password2){
            
            $api.post('/user/change_password', {email: email, password: password1})
            .then((e)=> {
                setIncor('')
                setCorrect(<p>Данные успешно изменены<br/>Через 3 секунды вы будете перенаправлены на главную страницу...</p>)
                setTimeout(() => navigate('/'), 3000)
                
            
            
            
            })
            .catch((e) => setIncor(e.response.data))
        } else {
            setIncor("Пароли не совпадают")
        }
    }

  return (
        <>
<div className='reset-pass-container'>
        <Helmet>
          <title>{`Agent KEI | Восстановление пароля`}</title>
          <meta name="twitter:title" content={`Agent KEI | Восстановление пароля`}/>
          <meta name="twitter:description" content="Street cube - это более 100 моделей штанов и брюк разного формата - джоггерры, карго, чиносы, джинсы, ширина, парашюты, а ещё куртки, oversize толстовки и футболки! Магазин в Санкт-Петербурге. Доставка по всей России!"/>
          <meta property="og:title" content={`Agent KEI | Восстановление пароля`}/>
          <meta property="og:description" name="description" content="Street cube - это более 100 моделей штанов и брюк разного формата - джоггерры, карго, чиносы, джинсы, ширина, парашюты, а ещё куртки, oversize толстовки и футболки! Магазин в Санкт-Петербурге. Доставка по всей России!" />
          <meta name="description" content="Street cube - это более 100 моделей штанов и брюк разного формата - джоггерры, карго, чиносы, джинсы, ширина, парашюты, а ещё куртки, oversize толстовки и футболки! Магазин в Санкт-Петербурге. Доставка по всей России!" />
        </Helmet>
        <Header/>
        <div className='form-container-reset'>
            <h1>Восстановление пароля</h1>

            {!toChange ? <>
            <p className='to-put-email'>Введите адрес электронной почты и мы отправим код для восстановление пароля.</p>
            
            
            {!toPutCode ? 
           <>
           <div className='input-email'>
            <p>E-mail</p>
           <input maxLength="60" placeholder="введите свой email"type="text" onChange={(e) => setPochta(e.target.value)}></input>
           
           </div>
           <p className='incorrect'>{incorrect}</p> 
           </>:
            <div className='input-email'>
            <p>E-mail</p>
            <input maxLength="60" placeholder="enter email..."type="text" value={email}></input>
            </div>
            }
            
            {!toPutCode ? <button onClick={() => handleReset()}>Отправить</button> : null}
            
            
            
            </>
            : null}
            

            {toPutCode && !toChange? 
            <>
            <div className='input-password'>
                <p>Введите код, отправленный вам на почту</p>
                <input maxLength="5" type="text" onChange={(e) => setCodeInput(e.target.value)} placeholder='введите код'/>
            </div>
            <p className='incorrect'>{incorrect2}</p>
            <button onClick={handleCodeCheck}>Проверить</button>
            
            
            
            </> :
            null
            }
            {toChange ? 
            <>
            <div className='input-password'>
                <p>Введите новый пароль</p>
                <input maxLength="16" type="password" onChange={(e) => setPassword1(e.target.value)} placeholder='* * * * * * * * * * * * * * * * * * * * *'/>
            </div>
            <div className='input-password'>
                <p>Введите пароль еще раз</p>
                <input maxLength="16" type="password" onChange={(e) => setPassword2(e.target.value)} placeholder='* * * * * * * * * * * * * * * * * * * * *'/>
            </div>
            <p className='incorrect'>{incorrect}</p>
            <p className='correct'>{correct}</p>
            {correct ? null :
            <button onClick={handleCheck}>Изменить пароль</button>
            }
            
            </>
            : null}

        </div>
        <Footer/>
    </div>
    </>
    
  )
}

export default ResetPass