import React from 'react';

import { Link } from 'react-router-dom';

import './home.css'
import Footer from '../../components/footer/Footer';

import {useSelector, useDispatch} from 'react-redux'

// import { getProductsSelector, toogleSearchInput, productSelector, setProductToView, getLoading, getBusketSelector, userSelector, authSelector, addBusket, setVk, vkSelector, searchSelector, setCategoryFilter} from '../../components/slices/SlicesStore';
import queryString from 'query-string';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { useNavigate } from "react-router-dom";
// import { Swiper } from 'swiper/types';
import Swiper from '../../components/slider/Swiper';
import ContentHome from './ContentHome';
import Header from '../../components/header/Header';






export default function HomePage() {
    // const dispatch = useDispatch()
    // const navigate = useNavigate()
    // let products = useSelector(getProductsSelector.getProducts)
    // const isLoading = useSelector(getLoading.getIsLoading)


    // const [scrolled, setScrolled] = React.useState(false);

    // useScrollPosition(
    //     ({ prevPos, currPos }) => {
    //     const isScrolled = currPos.y < -50;
    //     if (isScrolled !== scrolled) {
    //         setScrolled(isScrolled);
    //     }
    //     },
    //     [scrolled]
    // );

    // const busket = useSelector(getBusketSelector.getBusket);
    // const isAuth = useSelector(authSelector.getAuth)
    // const hadleSearchOpen = () => {
    //     dispatch(toogleSearchInput())
    //     document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    // }
    // const isOpenSearch = useSelector(searchSelector.getSearchInput)

    // const filterHydi = products.filter((event) => event.category === "Толстовки");
  return (
    // <>
    // {!isLoading ?
 
    <div className='home-container'>
      {/* Conten Home page */}
      <main>
          <ContentHome/>
      </main>
      {/* footer */}
      
    </div>
// : null

//     }
//     </>
    
  )
}


