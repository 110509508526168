import React from 'react'
import './product-page.css'
import { useEffect } from 'react';
import OrderDekstop from './Dekstop';
// import OrderMobile from '../components/orderpage/Mobile';
// import OrderTablet from '../components/orderpage/Tablet';
import Helmet from 'react-helmet';
function ProductPage() {
    
    // const [width, setWidth] = React.useState(window.innerWidth );
    const [map, setMap] = React.useState(false);   
    let initialWidth = window.innerWidth 
    return (
        <div>
        <Helmet>
          <title>Agent KEI | Корзина</title>
          <meta name="twitter:title" content="Agent KEI | Корзина"/>
          <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
          <meta property="og:title" content="Agent KEI | Корзина"/>
          <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
          <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
        </Helmet>
            <OrderDekstop/>
        </div>
    )
}

export default ProductPage
