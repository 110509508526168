import React, { useEffect, useState } from 'react'
import Card from './Card';
import './products.scss'
import Sidebar from './Sidebar';
import searchIcons from '../../../images/icons/search-icons.svg'
import {useSelector, useDispatch} from 'react-redux'
import { productSelector, getProductsSelector,setProductToView, getActualFilters, setAllFilters, setOneTypes, typesSelector, typeSelector, setOpensFilter, setType, setSeriaFilter, seriaSelector, setCategories, categoriesSelector, categorySelector, memorySelector, scrollSelector, setScroll, colorSelector, razmSelector, setMemoryFilter, setCategoryFilter,setExFilter, setColorFilter, setRazmFilter, exFilterSelector, markSelector, setMark, getLoading, allFiltersSelector} from '../../slices/SlicesStore';
import filterBtn from '../../../images/icons/filter-icons.svg'
import {getProducts} from '../../requests'
import BannerPage from '../../header/BannerPage';
import { useLocation } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";
// import TestCard from '../../../pages/Home/TestCard'
import { IoIosArrowDown } from "react-icons/io";
import { LuSettings2 } from "react-icons/lu";
import { all } from 'axios';
import { IoClose } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';

function Products() {
  const [isOpen, setOpen] = useState(false)
  const data = useSelector(getProductsSelector.getProducts)
  const isLoading = useSelector(getLoading.getIsLoading)
  const location = useLocation()
  const filterEx = useSelector(exFilterSelector.getExFilter)
  const productToSelect = useSelector(productSelector.getProductToSelect)
  const dispatch = useDispatch()
  const usedCategory = useSelector(categorySelector.getCategory)
  const [nowtype, setNowType] = React.useState(null)
  const types = useSelector(typesSelector.getTypes)
  const navigate = useNavigate() 
  function translit(word){
    var converter = {
      'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
      'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
      'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
      'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
      'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
      'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
      'э': 'e',    'ю': 'yu',   'я': 'ya'
    };
   
    word = word.toLowerCase();
    
    var answer = '';
    for (var i = 0; i < word.length; ++i ) {
      if (converter[word[i]] == undefined){
        answer += word[i];
      } else {
        answer += converter[word[i]];
      }
    }
   
    answer = answer.replace(/[^-0-9a-z]/g, '-');
    answer = answer.replace(/[-]+/g, '-');
    answer = answer.replace(/^\-|-$/g, ''); 
    return answer;
  }

  const sort_by = (field, reverse, primer) => {
    
    
    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }
  const productsToView = useSelector(productSelector.getProductToSelect)
  // console.log(productsToView)

  const [valueSearch,setValueSearch] = React.useState("");
  const searchProductHandler = (text) => {
    if(searchProductsOpend){
      setValueSearch("");
    }

    setSearchProductsOpend(!searchProductsOpend);
  }

  const setOpenSide = (e) => {
    let elem = document.querySelector('.filter__button')
    if (isOpen){
      elem.style.position = "sticky" 
    } else {
      elem.style.position = "fixed" 
    }

  }

  const colorFilter = useSelector(colorSelector.getColor)
  const markFilter = useSelector(markSelector.getMark)
  const [filtered ,setFiltered] = React.useState(false);
  const memoryFilter  = useSelector(memorySelector.getMemory)
  const categoryFilter = useSelector(categorySelector.getCategory)
  const razmFilter = useSelector(razmSelector.getRazm)
  const seriaFilter = useSelector(seriaSelector.getSeria)
  const [saleFilter ,setSaleFilter] = React.useState(null);

  
  // const pageScrollPosition = useSelector(scrollSelector.getScroll);

    // React.useEffect(() => {


        


    //     if (pageScrollPosition) {
    //             setTimeout(()=> window.scrollTo(0, pageScrollPosition), 3000);

    //     }






        

    // }, []);

 
  React.useEffect(() => {
    setSaleFilter(window.location.href.split("/")[4])
  }, [window.location.href]);
  const [sortFilter ,setSortFilter] = React.useState(null);
  const [result, setResult] = React.useState([])
  const type = useSelector(typeSelector.getType)
  function filteredReset(products, filters) {
    var filteredProducts = products;
    // if (exFilter && exFilter.category && !filters.category){
    //   filteredProducts = filteredProducts.filter(function (elem){
    //     if (elem.category === exFilter.category){
    //       return elem
    //     }
    //   })

    // })
    // filters = [{name: Серия, value: "iPhone 11"}, {name: "Цвет", value: "Серый"}]
    console.log(allFilters)
    filteredProducts = filteredProducts.filter((elem) => {

        if (usedCategory){
          if (type){
            return elem.category === usedCategory && elem.type === type
          } else {
            return  elem.category === usedCategory 
          }
           
        } else {
          return true
        }
       

      

    })
    if (saleFilter == "sales"){

      filteredProducts = filteredProducts.filter((elem) => Number(elem.prevPrice) != 0 && elem.prevPrice < elem.newPrice)
    }
    console.log(filteredProducts.length)
    return filteredProducts

  }
  function filteredData(products, filters) {
    var filteredProducts = products;
    // if (exFilter && exFilter.category && !filters.category){
    //   filteredProducts = filteredProducts.filter(function (elem){
    //     if (elem.category === exFilter.category){
    //       return elem
    //     }
    //   })

    // })
    // filters = [{name: Серия, value: "iPhone 11"}, {name: "Цвет", value: "Серый"}]
    console.log(allFilters)
    filteredProducts = filteredProducts.filter((elem) => {
        let flag = false;
        if (!elem.mods && allFilters.length != 0 ){
          return false
        }
        let count = 0
        allFilters.map((filter) => {
          
            for (let j = 0; j < elem.mods.length; j++){
              if (elem.mods[j].name == filter.name){
                if ((elem.mods[j].value == filter.value)){
                  count++;
                }
              }

  
          }


        })
        if (usedCategory){
          if (type){
            return count === allFilters.length && elem.category === usedCategory && elem.type === type
          } else {
            return count === allFilters.length && elem.category === usedCategory 
          }
           
        } else {
          return true
        }
       

      

    })
    if (saleFilter == "sales"){

      filteredProducts = filteredProducts.filter((elem) => Number(elem.prevPrice) != 0 && elem.prevPrice < elem.newPrice)
    }
    console.log(filteredProducts.length)
    return filteredProducts

  }
  const handleChange2 = event => {
    setFilterOpend(!filterOpend);
  }
  const handleReset = event => {
    dispatch(setColorFilter(null))
    dispatch(setMemoryFilter(null))
    dispatch(setCategoryFilter(null))
    dispatch(setRazmFilter(null))
    setSaleFilter(null)
  }
  const handleReset2 = event => {
    dispatch(setAllFilters([]))
    
    
    let temp = filters_values.get(usedCategory)
    for (let i = 0; i < temp.length; i++){
      let sidebar_buttons = document.querySelectorAll(`.button_for1.${translit(temp[i].name)}`)
      for( let i = 0; i < sidebar_buttons.length; i++){
          sidebar_buttons[i].style.color= 'black';

      }
    }
    setResult(filteredReset(data))

    

  }
  const allFilters = useSelector(allFiltersSelector.getAllFilters)
  const handleChange = event => {
    
    let temp = filters_values.get(usedCategory)
    let temp2 = []
    let flag = false
    let flag2 = false
    for (let i = 0; i < temp.length; i++){

      if (translit(temp[i].name) == event.target.className.split(' ')[1]){ 
        flag = true
        if (allFilters.length == 0){
          dispatch(setAllFilters([{name: temp[i].name, value: event.target.textContent}]))
          event.target.style.color = 'tomato';
        } else {
          for (let j = 0; j < allFilters.length; j++){
            if (allFilters[j].name == temp[i].name){
              flag2 = true;
              if (allFilters[j].value == event.target.textContent){
                dispatch(setAllFilters(allFilters.filter((event) => event.name !== temp[i].name)))
                event.target.style.color = 'black';
              } else {
                temp2 = allFilters.filter((event) => event.name !== temp[i].name)
                temp2.push({name: temp[i].name, value: event.target.textContent})
                dispatch(setAllFilters(temp2))
                let sidebar_buttons = document.querySelectorAll(`.button_for.${translit(temp[i].name)}`)
                for( let i = 0; i < sidebar_buttons.length; i++){
                  
                  if (sidebar_buttons[i].textContent ===  event.target.textContent){
                    sidebar_buttons[i].style.color = 'tomato';
                  } else {
                    sidebar_buttons[i].style.color= 'black';
                  }
                }

              
              }
            }
          }
          if (!flag2){
            event.target.style.color = 'tomato';
            dispatch(setAllFilters([...allFilters, {name: temp[i].name, value: event.target.textContent}]))
          }

        }

        //Проверка через fr по allFilters по i name есть ли уже такой фильтр, если есть удалить, если нет добавить
        
        //
      }
      if(flag){
        break
      }
    }
    //По итогу handleChange должен получится allFilters = [{name: Название фильтра, value: Значение}]
    //потом именить зависимость для фильтрации в useEffect 167 строка(сделал), он вызовет функцию filteredData, где будут фильтроваться товары для вывода
  
    
    dispatch(setProductToView(12))
    // setTimeout(()=>window.scrollTo({top:0, behavior: 'smooth'}),150)
    
   
  }
  React.useEffect(() => {
    setResult(filteredData(data, {color: colorFilter, allFilters: allFilters, searchFiler: valueSearch}))
    setFiltered(true)

  }, [type, data, usedCategory]);

  
  const handleApplyfilters = () => {
    console.log(22222)
    setResult(filteredData(data, {color: colorFilter, allFilters: allFilters, searchFiler: valueSearch}))
    setFiltered(true)
    
  }
  


  const [pokazSort, setPokazSort] = React.useState(false);
  const handlePokazSort= () => {
    setPokazSort(!pokazSort);
  }

  
  const [filterOpend, setFilterOpend] = React.useState(false);
  const handleFilterOpend = () => {
    setFilterOpend(!filterOpend);
  }

    
  const[searchProductsOpend,setSearchProductsOpend] = React.useState(false);
  const filteredSearch = data.filter((text) => {
    return  valueSearch === "" || valueSearch.toLocaleLowerCase() !== text.name.toLocaleLowerCase() ? undefined : text.name.toLowerCase()?.includes(valueSearch.toLowerCase());
  })
  
  function getPosition(el) {

    var x = 0,
        y = 0;

    while (el != null && (el.tagName || '').toLowerCase() != 'html') {
        x += el.offsetLeft || 0; 
        y += el.offsetTop || 0;
        el = el.parentElement;
    }

    return { x: parseInt(x, 10), y: parseInt(y, 10) };
  }
  React.useEffect(() => {
    if (categoryFilter){
      let sidebar_buttons = document.querySelectorAll(".category")
      for( let i = 0; i < sidebar_buttons.length; i++){
        if (sidebar_buttons[i].textContent ===  categoryFilter){
          sidebar_buttons[i].style.textDecoration = 'underline';
        } else {
          sidebar_buttons[i].style.textDecoration = 'none';
        }
      }

    }
    


  }, [filterEx, filterOpend, categoryFilter]); 
  // console.log(productsToView, result)

  const [width, setWidth] = React.useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const categories = useSelector(categoriesSelector.getCategories)
  const [filters_values,setFiltersValue] = useState(null);
  useEffect(() => {
    let tempAll = {}
    let tempKeys =[]
    let temp = new Map();
    let tempTypes = []
    if (!isLoading && data.length != 0){
    if(allFilters.length == 0){
      for (let i = 0; i < data.length; i++){
        if (data[i].mods){
        for (let j = 0; j < data[i].mods.length; j++){
          if (!temp.has(`${data[i].category} ${data[i].type}`)){
            temp.set(`${data[i].category} ${data[i].type}`, [data[i].mods[j]])

          } else{
            let temp2 = temp.get(`${data[i].category} ${data[i].type}`);
            let flag = false
            for (let k = 0; k < temp2.length; k++){

              if (temp2[k].name == data[i].mods[j].name){
                flag = true
                if (!Array.isArray(temp2[k].value)){
                  temp2[k] = {name: temp2[k].name, value: [temp2[k].value]}
                }
                if (!temp2[k].value.includes(data[i].mods[j].value)){
                  temp2[k].value.push(data[i].mods[j].value)
                }

              }
            }
            if (!flag){
              temp2.push(data[i].mods[j])
            }
            temp.set(`${data[i].category} ${data[i].type}`, temp2)
          }
        }
        } else {
          if (!tempKeys.includes((data[i].category))){
            tempKeys.push(data[i].category)
          }

        }
      }
      for (let i = 0; i < data.length; i++){
        if (data[i].mods){
        for (let j = 0; j < data[i].mods.length; j++){
          if (!temp.has(`${data[i].category}`)){
            temp.set(`${data[i].category}`, [data[i].mods[j]])
          } else{
            let temp2 = temp.get(`${data[i].category}`);
            let flag = false
            for (let k = 0; k < temp2.length; k++){

              if (temp2[k].name == data[i].mods[j].name){
                flag = true
                if (!Array.isArray(temp2[k].value)){
                  temp2[k] = {name: temp2[k].name, value: [temp2[k].value]}
                }
                if (!temp2[k].value.includes(data[i].mods[j].value)){
                  temp2[k].value.push(data[i].mods[j].value)
                }

              }
            }
            if (!flag){
              temp2.push(data[i].mods[j])
            }
            temp.set(`${data[i].category}`, temp2)
          }
        }
        } else {
          if (!tempKeys.includes((data[i].category))){
            tempKeys.push(data[i].category)
          }

        }
      }
    } else {
      let result = filteredData(data)
      for (let i = 0; i < result.length; i++){
        if (result[i].mods){
        for (let j = 0; j < result[i].mods.length; j++){
          if (!temp.has(`${result[i].category} ${result[i].type}`)){
            temp.set(`${result[i].category} ${result[i].type}`, [result[i].mods[j]])

          } else{
            let temp2 = temp.get(`${result[i].category} ${result[i].type}`);
            let flag = false
            for (let k = 0; k < temp2.length; k++){

              if (temp2[k].name == result[i].mods[j].name){
                flag = true
                if (!Array.isArray(temp2[k].value)){
                  temp2[k] = {name: temp2[k].name, value: [temp2[k].value]}
                }
                if (!temp2[k].value.includes(result[i].mods[j].value)){
                  temp2[k].value.push(result[i].mods[j].value)
                }

              }
            }
            if (!flag){
              temp2.push(result[i].mods[j])
            }
            temp.set(`${result[i].category} ${result[i].type}`, temp2)
          }
        }
        } else {
          if (!tempKeys.includes((result[i].category))){
            tempKeys.push(result[i].category)
          }

        }
      }
      for (let i = 0; i < result.length; i++){
        if (result[i].mods){
        for (let j = 0; j < result[i].mods.length; j++){
          if (!temp.has(`${result[i].category}`)){
            temp.set(`${result[i].category}`, [result[i].mods[j]])
          } else{
            let temp2 = temp.get(`${result[i].category}`);
            let flag = false
            for (let k = 0; k < temp2.length; k++){

              if (temp2[k].name == result[i].mods[j].name){
                flag = true
                if (!Array.isArray(temp2[k].value)){
                  temp2[k] = {name: temp2[k].name, value: [temp2[k].value]}
                }
                if (!temp2[k].value.includes(result[i].mods[j].value)){
                  temp2[k].value.push(result[i].mods[j].value)
                }

              }
            }
            if (!flag){
              temp2.push(result[i].mods[j])
            }
            temp.set(`${result[i].category}`, temp2)
          }
        }
        } else {
          if (!tempKeys.includes((result[i].category))){
            tempKeys.push(result[i].category)
          }

        }
      }

      console.log(allFilters)

    }
    setFiltersValue(temp)
    if (usedCategory == "Apple"){
        let one_product = data.filter((elem) => elem.category === usedCategory)
      let temp5 = []

      for (let i = 0; i < one_product.length; i++){
        if (!temp5.includes(one_product[i].type)){
          temp5.push(one_product[i].type)
        }
      }
      dispatch(setOneTypes(temp5))
    }

    for(const key of temp.keys()){
      if(!tempKeys.includes(key.split(' ')[0])){
        tempKeys.push(key.split(' ')[0])
      }
      
    }
    }
    
  }, [isLoading, data, allFilters])
  const handleChangeCategory = (e) => {
    dispatch(setCategoryFilter(e))
  }
  const handleClose = () => {
    setOpen(!isOpen);
  }
  const [actual_filters, setActualFilters] = React.useState([])
  useEffect(() => {
    if (filters_values){
    if (type){
        let temp = filters_values.get(`${usedCategory} ${type}`)
        if (temp){
            for (let i = 0; i < temp.length; i++){
                if (!isNaN(temp[i].value[0]) && Array.isArray(temp[i].value)){
                    temp[i].value = temp[i].value.map(elem => Number(elem))
                    console.log(temp[i].value)
                    temp[i].value.sort(function (a, b) {
                        return a - b;
                      });
                    console.log(temp[i].value)
                }
                
                
            }
        }
        setActualFilters(temp)

    } else {
        let temp = filters_values.get(usedCategory)
        if (temp ){
            for (let i = 0; i < temp.length; i++){
                
                if (!isNaN(temp[i].value[0]) && Array.isArray(temp[i].value)){
                    temp[i].value = temp[i].value.map(elem => Number(elem))
                    console.log(temp[i].value)
                    temp[i].value.sort(function (a, b) {
                        return a - b;
                      });
                    console.log(temp[i].value)
                }
                
                
            }
        }

        setActualFilters(temp)
    }
  }
}, [type, filters_values,usedCategory])
  return (
  
    <div className="">
       <div className="categoria_header_catalog">
        <div className="containe_rback_button_catalog">
        <h3 className='title_catalog' onClick={() => {dispatch(setProductToView(12));dispatch(setCategoryFilter(null));dispatch(setType(null));dispatch(setOneTypes([]))}}>Каталог</h3>
              {
                usedCategory ? <p className='back_button_catalog' onClick={() => {dispatch(setProductToView(12));dispatch(setCategoryFilter(null)); dispatch(setType(null));dispatch(setOneTypes([]))}}><IoIosArrowBack style={{color: "rgb(191, 72, 0)", fontSize: "25px"}} /> Назад</p>: null
              }
        </div>
       <section id='category__block'>
          {usedCategory ? 
          
          <>
           <button className='categories__change__block_active' onClick={() => {dispatch(setProductToView(12));dispatch(setCategoryFilter(usedCategory));dispatch(setType(null));}}>{usedCategory}</button>
           {types.map((elem) => <>
           {

            <>
          
              {elem === type ? 
                <button className='type-active' onClick={() => {dispatch(setProductToView(12));dispatch(setType(elem));dispatch(setAllFilters([]));dispatch(setOpensFilter([]))}}>{elem}</button>
              :
               <button className='type-non-active' onClick={() => {dispatch(setProductToView(12));dispatch(setType(elem));dispatch(setAllFilters([]));dispatch(setOpensFilter([]))}}>{elem}</button>
              }
        
          </>
          }
           </>
           
          )}
          {/* <button className='sales-btn' onClick={() => {
            if (window.location.href.includes('sales')){
                navigate('/catalog')
            } else {
                navigate('/catalog/sales')
            }
          }}>Скидки</button> */}

          
          </> 
          :
          <>
          {categories.map((e) => {return <>
            {
                <button className='categories__change__block' onClick={() => {dispatch(setProductToView(12));handleChangeCategory(e);dispatch(setOpensFilter([]))}}>{e}</button>
            }
            </>
          })}  
          {/* <button className='sales-btn' onClick={() => {
            if (window.location.href.includes('sales')){
                navigate('/catalog')
            } else {
                navigate('/catalog/sales')
            }
          }}>Скидки</button> */}
          </>
          }

          

        </section>
       </div>
       
       {
      filters_values ? 
      <>
      <div className={usedCategory && filters_values.has(usedCategory+ " " + type) ? "catalog__container" : "catalog__container"}>
      {/* sidebar sction */}
      {
        
        usedCategory && filters_values.has(usedCategory) && actual_filters ? 
        <>
         
        <section id="sidebar__block" className={isOpen ? 'filter_active'  : '' } >
        {isOpen ? 
        <div className='blur-container-start' tabindex='1' onClick={(e) => handleClose()}></div>
        : null}
        
        <Sidebar handleChange={handleChange} actual_filters={actual_filters}toApply={handleApplyfilters} filters={filters_values} handleClose={handleClose} handleFilterOpend={handleFilterOpend} handleReset2={handleReset2} handleChange2={handleChange2}/>
      </section>
      {
        filterOpend === true && width <= 1024 ? <div className='filter-tablet-version'>
          <Sidebar handleChange={handleChange} actual_filters={actual_filters} toApply={handleApplyfilters} handleClose={handleClose} filters={filters_values} handleFilterOpend={handleFilterOpend} handleReset2={handleReset2} handleChange2={handleChange2}/>
        </div>: ''
      }        
       </> 
        : null


      }


      {/* catalog section */}
      
      <div className="catalog__section">
        
        {/* <section id='category__block'>
          {usedCategory ? 
          
          <>
           <button className='categories__change__block_active'>{usedCategory}</button>
           {types.map((elem) => <>
           
          {elem === type ? 
            <button className='type-active' onClick={() => {dispatch(setType(elem));dispatch(setAllFilters([]));dispatch(setOpensFilter([]))}}>{elem}</button>
          :
           <button className='type-non-active' onClick={() => {dispatch(setType(elem));dispatch(setAllFilters([]));dispatch(setOpensFilter([]))}}>{elem}</button>
          }
           
           </>
          )}
          </> 
          :
          <>
          {categories.map((e) => {return <>
            {
                <button className='categories__change__block' onClick={() => {handleChangeCategory(e);dispatch(setOpensFilter([]))}}>{e}</button>
            }
            </>
          })}  
          </>
          }

        </section> */}

        <section id="catalog__block">
          {
            result.length > 0 ?
            <>
                {result.slice(0, productsToView <= data.length ? productsToView :  data.length).map((obj) => 
                  <Card 
                    id={obj.id}
                    key={obj.id}
                    name={obj.name}
                    newPrice={obj.newPrice}
                    prevPrice={obj.prevPrice}
                    // size={obj.size}
                    // sizes = {obj.sizes}
                    image_url={obj.imageUrl}
                    imgCount={obj.imgCount}
                  />
              )} 
            </>
            
            : <p>ничего не найдено</p>
          }
          
        </section>

        {/* filter button */}
        { usedCategory && filters_values.has(usedCategory) ?
          <>
        {isOpen ? 
          <button onClick={(e) => {setOpen(!isOpen);setOpenSide(e)}} className="filter__button"><IoClose style={{fontSize: "25px", color: "#FFF"}}/></button>
          :
          <button onClick={(e) => {setOpen(!isOpen);setOpenSide(e)}} className="filter__button"><LuSettings2 style={{fontSize: "25px", color: "#fff"}}/></button>
          }
          
          </>
        : null
        }

       
        {/* <div>menu</div> */}
        {/* <button onClick={handleFilterOpend} className="filter__button"><LuSettings2 style={{fontSize: "25px", color: "#FFF"}}/></button> */}
      
        {/* more button */}
        {
            result.length > productToSelect ?
            <button className='more__button' onClick={() => dispatch(setProductToView(12 + productToSelect))}>
            Прогрузить ещё
          </button> : ""
        }
        
        </div>
      </div>
     </> 
    : null
    }
       

    </div>
    
  )
}

export default Products